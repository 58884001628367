import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Divider,
  Box,
  Typography,
  Stack,
  Button,
  Skeleton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ResumeUploadCard from "./ResumeUploadCard";
import { LoadingButton } from "@mui/lab";
import {
  candidateRegisterFlow,
  getResumeListById,
  getJobCxoOrNot,
  postCxoEligibility,
  stepperStepInfo
} from "../../../../fetchAPI";
import NoResumeUploaded from "./NoResumeUploaded";
import ApplyCxoModal from "./ApplyCxoModal";
import { fetchIpAddress } from "../../../helpers/helperFunctions";

export default function UploadResume({ handleNext, handleBack, job_id, settingCandidateData, candLoader, profileStrength, candidateData }) {
  const fileUploadRef = useRef("");
  const [resumeList, setResumeList] = useState([]);
  const [getUserInfo, setGetUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [resumeListLoader, setResumeListLoader] = useState(false);
  const [uploadedResume, setUploadedResume] = useState("");
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isSelectedResume, setIsSelectedResume] = useState(false);
  const [openCxoModal, setOpenCxoModal] = useState(false);

  const getResumeList = () => {
    setResumeListLoader(true);
    getResumeListById()
      .then((response) => {
        if (response && response.success) {
          setResumeList(response.response.data);
        }
      })
      .finally(() => setResumeListLoader(false));
  };

  useEffect(() => {
    getResumeList();
  }, []);

  const applyingtoTob = () => {
    const formData = new FormData();
    if (isSelectedResume) {
      formData.append("resume", selectedResumeId);
    } else {
      formData.append("resume", uploadedResume);
    }

    candidateRegisterFlow("resume", job_id, formData)
      .then((response) => {
        if (response && response.success) {
          handleNext();
          setUploadedResume("");
          // setResponseMessage(response.response?.message)
          // history.push(`/jobs/${job_id}`)
          //gtag code - don't remove
          window.dataLayer.push({
            'event': 'candidateRegistrationStepperFinalStepComplete'
          });
          //gtag code ended
          //esk
          window.esk('track', 'candidateRegistrationStepperFinalStepComplete');
          //end esk
        } else {
          setResponseMessage(response.errors[0]?.message);
        }
      })
      .finally(() => setLoading(false), setResponseMessage(""));
  }

  const [sendRequestLoading, setSendRequestLoading] = useState(false)
  const [isRequestSucceed, setIsRequestSucceed] = useState(false)
  const postCxoRequest = () => {
    setSendRequestLoading(true)
    const formData = new FormData();
    if (isSelectedResume) {
      formData.append("resume", selectedResumeId);
    } else {
      formData.append("resume", uploadedResume);
    }
    formData.append('job_id', job_id)

    postCxoEligibility(formData)
      .then((response) => {
        if (response && response.success) {
          setIsRequestSucceed(true)
        }
      }).finally(() => setSendRequestLoading(false))
  }
  const handleSUbmit = () => {
    setLoading(true);
    if(selectedResumeId === '' && uploadedResume===''){
      setLoading(false)
      return setResponseMessage([`Please select a Resume`])
    }

    if (uploadedResume) {
      const allowedFormats = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ];
      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

      if (!allowedFormats.includes(uploadedResume.type)) {
        setLoading(false);
        return setResponseMessage([
          "Only doc, docx, and pdf formats are supported.",
        ]);
      }

      if (uploadedResume.size > maxSize) {
        setLoading(false);
        return setResponseMessage([
          "File size exceeds the maximum limit of 10 MB.",
        ]);
      }
    }

    if (job_id && profileStrength < process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY) {
      setLoading(false);
      return setResponseMessage([`Your profile strength is below ${process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY}. Please complete all the steps to apply for this job.`])
    } else {
      getJobCxoOrNot(job_id)
        .then((response) => {
          if (response && response.response?.data) {
            if (job_id && candidateData?.is_cxo_eligible == 0) {
              setLoading(false);
              return setOpenCxoModal(true);
            } else {
              applyingtoTob()
            }
          } else {
            applyingtoTob()
          }
        })
    }

    const ip = fetchIpAddress();
    const platform = "web";
    const stepNo = 6;

    stepperStepInfo(stepNo, ip, platform).then((response) => {
      if(response && response.success) {
        setLoading(false);
      }
    })
  };

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Typography align="left" variant="h6" sx={{ fontWeight: 600 }}>
          Upload Resume
        </Typography>
      </Box>
      <Divider />
      {candLoader ? (
        <div className="mt-3 col-lg-12 col-md-12">
          <Skeleton variant="text" height={60} />
          <Skeleton variant="text" height={50} />
          <div className="text-left">
            <Skeleton variant="text" />
          </div>
          <div className="mt-3 text-left">
            <Skeleton variant="rounded" height={120} />
          </div>
          <div className="text-left">
            <Skeleton variant="text" width={80} height={50} />
          </div>
        </div>
      ) : (
        <form onSubmit={(e) => [e.preventDefault(), handleSUbmit()]}>
          {responseMessage ? (
            <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
              {responseMessage?.map((item, index) => {
                return (
                  <center key={index}>
                    <small style={{ color: "red" }}> {responseMessage} </small>
                  </center>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <Grid container spacing={2} mt={3}>
            <Grid item lg={6} xs={12}>
              <Box
                sx={{
                  padding: 2,
                }}
              >
                <Stack spacing={0} alignItems={"center"}>
                  <Typography
                    align="center"
                    variant="h6"
                    sx={{ fontSize: "20px", fontWeight: 500 }}
                  >
                    Upload Your Resume
                  </Typography>
                  <Typography
                    align="center"
                    variant="h6"
                    sx={{ fontSize: "20px", fontWeight: 500, color: "#0275D8" }}
                  >
                    Get noticed by top employers!
                  </Typography>

                  <input
                    type="file"
                    hidden
                    id="upload-resume"
                    ref={fileUploadRef}
                    accept=".doc,.docx,.pdf" //  Accept only pdf files as backend only support pdf
                    onChange={(e) => [
                      setUploadedResume(e.target.files[0]),
                      setIsSelectedResume(false),
                      setSelectedResumeId(""),
                    ]}
                  />
                  <Box
                    mt={3}
                    sx={{
                      padding: 1,
                      border: "2px dashed #AFDAFF",
                      borderRadius: "6px",
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      endIcon={<CloudUploadIcon />}
                      sx={{
                        paddingLeft: 4,
                        paddingRight: 4,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                      onClick={() => fileUploadRef.current.click()}
                    >
                      {uploadedResume != ""
                        ? uploadedResume?.name
                        : "Upload Your Resume"}
                    </LoadingButton>
                  </Box>
                  <Typography
                    align="center"
                    variant="caption"
                    sx={{ fontSize: "15px", color: "#72737C", margin: 1 }}
                  >
                    Supported Formats: doc, docx, pdf, upto 10 MB
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ padding: 0, margin: 0 }}
              />
              <Box sx={{ padding: 2 }}>
                <Stack spacing={0}>
                  <Typography
                    align="left"
                    variant="h6"
                    sx={{ fontSize: "20px", fontWeight: 500 }}
                  >
                    Uploaded File
                  </Typography>
                  <Divider mt={1} />

                  {resumeListLoader ? (
                    <div className="mt-3 col-lg-12 col-md-12">
                      <Skeleton variant="text" height={80} />
                    </div>
                  ) : (
                    <Box mt={3} sx={{ padding: 1 }}>
                      {resumeList.length > 0 ? (
                        <ResumeUploadCard
                          resumeList={resumeList}
                          resumeListLoader={resumeListLoader}
                          setResumeListLoader={setResumeListLoader}
                          getResumeList={getResumeList}
                          setSelectedResumeId={setSelectedResumeId}
                          setIsSelectedResume={setIsSelectedResume}
                          selectedResumeId={selectedResumeId}
                          setUploadedResume={setUploadedResume}
                        />
                      ) : (
                        <NoResumeUploaded />
                      )}
                    </Box>
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
            <Button
              variant="outlined"
              // disabled
              sx={{ mr: 1, minWidth: {sm: "102px"} }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <LoadingButton
              variant="contained"
              type="submit"
              loading={loading}
              sx={{minWidth: {sm: "102px"}}}
            >
              Submit
            </LoadingButton>
          </Box>
        </form>
      )}
      <ApplyCxoModal postCxoRequest={postCxoRequest} jobId={job_id} open={openCxoModal} setOpen={setOpenCxoModal} handleNext={handleNext} isRequestSucceed={isRequestSucceed} sendRequestLoading={sendRequestLoading} />
    </>
  );
}