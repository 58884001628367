/**Imports */

import React, { useState, useContext, useEffect } from 'react'
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import Select from 'react-select'
import { Link, useHistory } from 'react-router-dom';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FormGroup from '@mui/material/FormGroup';
import { postJob, getBenifits, getBenifitChild } from '../../../fetchAPI';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { DropdownContext } from '../../context/DropdownContext';
import { Modal } from 'react-bootstrap'
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { CancelPresentation, PublishOutlined } from '@mui/icons-material';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import ReactQuill from 'react-quill';
import { Box, Button, Typography } from '@mui/material';
import { handleKeyDown } from '../../helpers/helperFunctions';
import JobPostCustomQuestions from './component/JobPostCustomQuestions';
import { useAlert } from '../../context/AlertContext';
import RequiredAsterisk from '../../components/CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk';

export function DeleteSVG() {
    return (
        <svg style={{cursor:'pointer'}} width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#FFEFEF" />
            <path d="M11.8649 19.3916C11.0537 20.2029 10.2446 21.012 9.43335 21.8232C8.93564 22.3209 8.93125 22.9568 9.39827 23.3975C9.56116 23.5539 9.77199 23.6508 9.99675 23.6727C10.2215 23.6946 10.4471 23.6401 10.6371 23.5181C10.7741 23.4236 10.9018 23.3165 11.0186 23.198L15.7019 18.5146C16.0462 18.1704 15.9541 18.1704 16.3115 18.5234C17.8945 20.1064 19.4776 21.6895 21.0606 23.2725C21.578 23.79 22.2117 23.8097 22.659 23.3405C22.8166 23.1791 22.9152 22.9693 22.9391 22.745C22.963 22.5207 22.9106 22.2948 22.7905 22.1039C22.698 21.9654 22.5915 21.8368 22.4726 21.7202C20.8896 20.1371 19.3043 18.5519 17.7213 16.9688C17.5262 16.7737 17.524 16.675 17.7213 16.4821C19.3043 14.8991 20.8874 13.316 22.4726 11.7308C22.5851 11.6228 22.6865 11.5038 22.7752 11.3756C22.9031 11.1772 22.9592 10.9411 22.9341 10.7064C22.9091 10.4717 22.8044 10.2527 22.6376 10.0858C22.4707 9.91897 22.2517 9.8143 22.017 9.78925C21.7823 9.76421 21.5462 9.8203 21.3478 9.9482C21.2106 10.0422 21.0834 10.1502 20.9685 10.2705L16.3071 14.9319C15.9475 15.2915 16.0484 15.2915 15.6778 14.921C14.1255 13.3686 12.5717 11.8148 11.0164 10.2596C10.9084 10.1471 10.7894 10.0457 10.6612 9.95698C10.4626 9.82472 10.2243 9.76533 9.98686 9.78891C9.74942 9.81248 9.52747 9.91755 9.35874 10.0863C9.19002 10.255 9.08494 10.477 9.06137 10.7144C9.0378 10.9518 9.09718 11.1901 9.22944 11.3887C9.3202 11.5153 9.42221 11.6335 9.53421 11.7417C11.1173 13.3248 12.7025 14.91 14.2855 16.4931C14.4697 16.6772 14.4719 16.7759 14.2855 16.9579C13.4852 17.7713 12.674 18.5826 11.8649 19.3916Z" fill="#DB1616" />
        </svg>
    )
}

// TODO: Fix Comments on this file

/**
 * Global Variables
 */

const experience = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]


/**
 * Component for Employers to be able to Post Jobs
 *  
 * @component
 */
function EmployerPostJob() {

    // UseState Declarations
    const [headHuntingJob, setHeadHuntingJob] = useState(false)
    const [cxoJob, setCxoJob] = useState(false)
    const [questionList, setquestionList] = useState([{ custom_question: "" }])
    const [jobDescription, setJobDescription] = useState('')
    const [jobRequirement, setJobRequirement] = useState('')
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [publishButton, setPublishButton] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [errors, setErrors] = useState('')
    const [negotiable, setNegotiable] = useState(false)
    const [notSpecific, setNotSpecific] = useState(false)
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedSkillArry, setSelectedSkillArray] = useState([]);
    const [yearsOfExperience, setYearsOfExperience] = useState('');
    const [jobSkills, setJobSkills] = useState([]);
    const [jobData, setJobData] = useState({
        job_title: '',
        industry: '',
        department: '',
        head_hunting: 0,
        position: '',
        job_deadline: '',
        job_description: '',
        job_requirement: '',
        qualification: '',
        job_type: '',
        salary_type: '',
        minimum_salary: '',
        maximum_salary: '',
        job_shift: '',
        negotiable: negotiable,
        not_specific: notSpecific,
        job_level: '',
        job_skill: jobSkills,
        number_of_vacancy: '',
        job_address: '',
        custom_question: '',
        job_status: 'draft',
        minimum_experience: '',
        maximum_experience: '',
        district_id: '',
        age_to : '',
        age_from : '',
        is_cxo : 0
    });

    const [sucessMessage, setSuccessMessage] = useState('')

    const alertContext = useAlert();
    // Custom question logic 

    const [customQuestionArray, setCustomQuestionArray] = useState([])
    const [customQuestionError, setCustomQuestionError] = useState(false)

    useEffect(() => {
        const hasError = customQuestionArray.some((item) => {
            return item.noOfOptions === "" || item.noOfOptions === 0 || item.options.length === 0 || item.options.some((option) => option === "");
        });
        setCustomQuestionError(hasError);
    }, [customQuestionArray])

    // const [tempQuestion, setTempQuestion] = useState('')
    // const [disableCustomQuesAddButton1, setDisableCustomQuesAddButton1] = useState(false)
    // const [disableCustomQuesAddButton2, setDisableCustomQuesAddButton2] = useState(false)

    // const addCustomQuestion = () => {
    //     setCustomQuestionArray([...customQuestionArray, tempQuestion])
    //     setTempQuestion('')
    //     document.getElementById('custom-question-add').reset()
    // }

    // const addSuggestCustomQuestion = (question) => {
    //     setCustomQuestionArray([...customQuestionArray, question])
    // }

    // const updateCustomQuArray = (index, e) => {
    //     const list = [...customQuestionArray]
    //     list[index] = e.target.value
    //     setCustomQuestionArray(list)
    // }

    // const deleteCustomQuestion = (index) => {
    //     const newList = customQuestionArray.filter((_, i) => i != index)
    //     setCustomQuestionArray(newList)

    // }

    useEffect(() => {
        setJobData({ ...jobData, custom_question: customQuestionArray })
    }, [customQuestionArray])


    // UseContext Declarations
    const { qualifications, types, levels, skills, departments, industries, positions, shifts, salary, currency, genders, district, apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext)
    // Use History declaration
    const history = useHistory();

    // Use Effect Declarations
    useEffect(() => {
        setJobData({ ...jobData, head_hunting: +headHuntingJob })
    }, [headHuntingJob])

    useEffect(() => {
        setJobData({ ...jobData, is_cxo: +cxoJob })
    }, [cxoJob])

    useEffect(() => {
        setApiCallingConditionSet({
            ...apiCallingConditionSet,
            qualification: true,
            type: true,
            level: true,
            department: true,
            industry: true,
            position: true,
            skill: true,
            shift: true,
            salaryType: true,
            district: true,
            currency: true
        })
    }, [])

    useEffect(() => {
        setJobData({ ...jobData, job_description: jobDescription, job_requirement: jobRequirement,  job_skill: jobSkills })
    }, [jobDescription, jobRequirement, jobSkills])

    const [jobBenefitCats, setjobBenefitCats] = useState([])
    const [jobBenefitCatOptns, setjobBenefitCatOptns] = useState([])
    const [currentBenefit, setCurrentBenefit] = useState(2)
    const [checkedBenefitList, setCheckedBenefitList] = useState([])

    const [jobPostLoader, setJobPostLoader] = useState(false)

    useEffect(() => {
        getBenifits()
            .then(response => {
                if (response && response.success) {
                    setjobBenefitCats(response.response.data)
                }
            })
    }, [])

    useEffect(() => {
        getBenifitChild(currentBenefit)
            .then(response => {
                if (response && response.success) {
                    setjobBenefitCatOptns(response.response.data?.child)
                }
            })
    }, [currentBenefit])

    function defaultSelectedCheckBox(item) {
        if (checkedBenefitList.includes(item.id)) {
            return 'checked=checked'
        } else {
            return ''
        }
    }

    useEffect(() => {
        setJobData({ ...jobData, benefit: checkedBenefitList.toString() })
    }, [checkedBenefitList])

    useEffect(() => {
        setJobData({ ...jobData, negotiable: +negotiable })
    }, [negotiable])

    useEffect(() => {
        setJobData({ ...jobData, not_specific: +notSpecific })
    }, [notSpecific])

    // Error Handling variable declaration
    let errorsObj = {
        job_title: '',
        industry: '',
        department: '',
        position: '',
        job_deadline: '',
        job_description: '',
        job_requirement: '',
        qualification: '',
        job_type: '',
        salary_type: '',
        minimum_salary: '',
        maximum_salary: '',
        negotiable: '',
        minimum_experience: '',
        maximum_experience: '',
        job_shift: '',
        job_level: '',
        job_skill: '',
        number_of_vacancy: '',
        job_address: '',
        district_id: '',
        customQuestion: '',
    };

    const handleChange = (name) => (event) => {
        if(name === "number_of_vacancy" && event.target.value.startsWith(0)) {
            const vacancy = event.target.value.replace(/^0+(?!$)/, '')
            setJobData({ ...jobData, [name]: vacancy })
        }
        else {
            setJobData({ ...jobData, [name]: event.target.value })
        }
    }

    const handleChangeSelect = (name) => (event) => {
        setJobData({ ...jobData, [name]: event.value })
    }

    const handleChangeMultiSelect = (name) => (event) => {
        setJobData({ ...jobData, [name]: event.map(val => val.value).toString() })
    }

    const cxoOption = [{label: "Monthly", value: "145"}]
    const cxoCurrency = [{label: "Taka", value: "248"}]

    /**
     * Validations
     */

    const validatePostJob = (body) => {


        let error = false;

        const errorObj = { ...errorsObj };

        if (body.job_title === '') {
            errorObj.job_title = 'Job Title is Required';
            error = true;
        }
        if (body.industry === '') {
            errorObj.industry = 'Industry is Required';
            error = true;
        }
        if (body.department === '') {
            errorObj.department = 'Department is Required';
            error = true;
        }
        if (body.position === '') {
            errorObj.position = 'Position is Required';
            error = true;
        }
        if (body.job_deadline === '') {
            errorObj.job_deadline = 'Deadline is Required';
            error = true;
        }
        if (body.job_description === '') {
            errorObj.job_description = 'Description is Required';
            error = true;
        }
        if (body.job_requirement === '') {
            errorObj.job_requirement = 'Requirement is Required';
            error = true;
        }
        if (body.qualification === '') {
            errorObj.qualification = 'Qualification is Required';
            error = true;
        }
        if (body.salary_type === '') {
            errorObj.salary_type = 'Salary Type is Required';
            error = true;
        }
        if (body.minimum_salary === '' && !negotiable) {
            errorObj.minimum_salary = 'Minimum Salary is Required';
            error = true;
        }else if (cxoJob && body.minimum_salary < 100000 && !negotiable){
            errorObj.minimum_salary = 'Cxo job minimum salary should not be less than 100000';
            error = true;
        }
        if (body.maximum_salary === '' && !negotiable) {
            errorObj.maximum_salary = 'Maximum Salary is Required';
            error = true;
        } 
        if (body.minimum_salary !== '' && body.maximum_salary !== '' && parseFloat(body.maximum_salary) < parseFloat(body.minimum_salary) && !negotiable) {
            errorObj.maximum_salary = 'Maximum salary should be greater than or equal to minimum salary';
            error = true;
        }
        if (cxoJob && negotiable) {
            errorObj.negotiable = 'Cxo job salary should not be negotiable';
            error = true;
        }
        if (body.minimum_experience === '') {
            errorObj.minimum_experience = 'Minimum Experience is Required';
            error = true;
        }
        if (body.maximum_experience === '') {
            errorObj.maximum_experience = 'Maximum Experience is Required';
            error = true;
        } else if (body.minimum_experience !== '' && body.maximum_experience !== '' && parseFloat(body.maximum_experience) <= parseFloat(body.minimum_experience)) {
            errorObj.maximum_experience = 'Maximum Experience should be greater than Minimum Experience';
            error = true;
        }
        if (body.job_shift === '') {
            errorObj.job_shift = 'Job Shift is Required';
            error = true;
        }
        if (body.job_type === '') {
            errorObj.job_type = 'Job Type is Required';
            error = true;
        }
        if (body.job_level === '') {
            errorObj.job_level = 'Level is Required';
            error = true;
        }
        if (body.job_skill == '') {
            errorObj.job_skill = 'Skill is Required';
            error = true;
        }
        if (body.number_of_vacancy === '' && !notSpecific) {
            errorObj.number_of_vacancy = 'Vacancies is Required';
            error = true;
        }
        if (body.job_address === '') {
            errorObj.job_address = 'Address is Required';
            error = true;
        }
        if (body.district_id === '') {
            errorObj.district_id = 'Location is Required';
            error = true;
        }
        if (body.age_from !== '' && body.age_to !== '' && parseFloat(body.age_to) <= parseFloat(body.age_from)) {
            errorObj.age_to = 'Maximum age should be greater than Minimum age';
            error = true;
        }
        if (customQuestionError) {
            errorObj.customQuestion = 'Options is required';
        }
        setErrors(errorObj);

        if (error || customQuestionError) {
            setJobData({ ...jobData, job_status: 'draft' })
        } else {
            setConfirmationModal(true)
            setConfirmationMessage('Are you sure you want to publish this job?')
        }
    }  

    let errDraftObj = {
        job_title: ''
    }
    const validateDraftJob = (body) => {
        setJobPostLoader(true)
        let error = false;

        const errorObj = { ...errDraftObj };

        if (body.job_title === '') {
            errorObj.job_title = 'Job Title is Required';
            error = true;
        }

        setErrors(errorObj);

        if (error) {
            window.scrollTo(0, 0);
            setJobPostLoader(false)
        } else {
            // setConfirmationModal(true)
            // setConfirmationMessage('Are you sure you want to save this job as draft?')
            saveJob(body);
        }
    }



    /**
     * Calling Post Job API 
     * 
     * @param {object} body - Sending Job Information
     * @return {object} Success or false json with Active Job Data
    */

    const postJobFunction = (body) => {

        postJob(body)
            .then((response) => {
                if (response && response.success) {
                    setJobPostLoader(false)
                    setConfirmationModal(false)
                    setSuccessMessage('Your job has been posted successfully!')
                    setSuccessModal(true);
                    setTimeout(() => {
                        history.push("/employer-manage-jobs");
                    }, 1500)
                }
                else {
                    setErrors(response.errors[0].message);
                    setErrorModal(true);
                    setJobPostLoader(false)
                    setConfirmationModal(false)
                }
            })
            .catch(error => console.log('Error while posting a job'))
            .finally(setJobPostLoader(false))
    }


    /**
   * Calling Save Job API 
   * 
   * @param {object} TODO: Review - Sending Employer ID and Status
   * @return {object} Success or false json
   */

    const saveJob = (body) => {
        postJob(body).then((response) => {
            if (response.success) {
                // setConfirmationModal(false)
                setSuccessMessage('Your job has been saved successfully!')
                setSuccessModal(true);
                setTimeout(() => {
                    history.push("/employer-manage-jobs");
                }, 1500)
                // setSuccessModal(true);
            }
            else {
                setErrors(response.errors[0].message);
                setErrorModal(true);
            }
        }).then(() => setJobPostLoader(false))
    }

    // Headhunting Switch
    const IOSSwitch = styled((props) => (
        <Switch edge='start' focusVisibleClassName=".Mui-focusVisible"  disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    function minimumDadeline() {
        var date = new Date();
        var minimumDate = moment(moment(date).add(0, 'd').format('YYYY-MM-DD'))

        return moment(minimumDate).format('YYYY-MM-DD')
    }

    const formatSkills = (data) => {
        return data.map(skill => ({
            label: skill.skill_name,
            value: skill.id
        }));
    }
    const skillsOption = formatSkills(skills);

    const handleSkillChange = (selectedOption) => {
        setSelectedSkill(selectedOption);
    };

    const addValueToArray = () => {

        if(yearsOfExperience < 0 || yearsOfExperience >100)
        {
            alertContext.setResponseMessage('Years of experience should be between 0 to 100');
            alertContext.setOpenErrorAlert(true);
            return;
        }

        if (selectedSkill && yearsOfExperience) {
            const newSkill = {
                skill_id: selectedSkill.value,
                year_of_experience: parseFloat(yearsOfExperience).toFixed(1)
            };
            const selectedSkills = {
                skill_id: selectedSkill.value,
                skill_name: selectedSkill.label,
                year_of_experience: parseFloat(yearsOfExperience).toFixed(1)
            };
            const isExists = selectedSkillArry?.find((skill) => skill.skill_id == selectedSkills.skill_id)
            if(!isExists){
                setJobSkills([...jobSkills, newSkill]);
                setSelectedSkillArray([...selectedSkillArry, selectedSkills]);
                setErrors({skill_exists: ""})
            }else{
                setErrors({skill_exists: `${isExists.skill_name} already added`})
            }
            // Reset the fields after adding
            setSelectedSkill(null);
            setYearsOfExperience('');
        }
    };

    const handleDelete = (id) => {
        const currentSkills = selectedSkillArry.filter((item) => item.skill_id !== id)
        setJobSkills(currentSkills);
        setSelectedSkillArray(currentSkills)
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-0'>
                    <EmployerDashboardSideBar />
                </div>
                <div className='col-lg-8 col-md-12'>
                    <div className='atb-card-dashboard'>
                        <div className='container'>
                            {/* <button type="button" className="btn btn-outline-primary btn-sm float-right d-lg-none"><i className="fa fa-bars" aria-hidden="true"></i> Menu</button> */}
                            <hr className='d-lg-none' />
                            <div className='row'>
                                <div className='col-lg-8 col-md-0'>
                                    <div className='text-left'>
                                        <h2 className='m-2 mt-4'> Post A Job</h2>
                                    </div>
                                </div>
                            </div><hr />
                            <form className='text-left'>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        {/* <FormGroup style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '10px' }}>
                                            <FormControlLabel
                                                control={<IOSSwitch defaultChecked={headHuntingJob} sx={{ m: 1 }} />}
                                                label="Head-Hunting Job"
                                                labelPlacement="start"
                                                onChange={() => setHeadHuntingJob(prevState => !prevState)}
                                            />
                                        </FormGroup> */}
                                        {/* <FormGroup style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '10px' }}>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} />}
                                                label="CXO Job"
                                                labelPlacement="start"
                                                onChange={() => setIsCxoJob(prevState => !prevState)}
                                            />
                                        </FormGroup> */}
                                        <FormGroup style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '10px' }}>
                                            <FormControlLabel
                                                control={<IOSSwitch defaultChecked={cxoJob} sx={{ m: 1 }} />}
                                                label="CXO Job"
                                                labelPlacement="start"
                                                onChange={() => setCxoJob(prevState => !prevState)}
                                            />
                                        </FormGroup>
                                        <div className="form-group m-t20">
                                            <label>Job Title <RequiredAsterisk/></label>
                                            <input type="text" className="form-control atb-input-box shadow p-3 mb-5 bg-white rounded" onChange={handleChange('job_title')} placeholder="Enter Job Title" required />
                                        </div>
                                        {errors.job_title && <Alert severity="error">{errors.job_title}</Alert>}
                                    </div>
                                    {/* Industry */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Industry <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {industries.map(ind => {
                                                    return (
                                                        { label: ind.name, value: ind.id }
                                                    )
                                                })
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('industry')}
                                            />
                                        </div>
                                        {errors.industry && <Alert severity="error">{errors.industry}</Alert>}
                                    </div>
                                    {/* Department  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Department <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {departments.map(dept => {
                                                    return (
                                                        { label: dept.name, value: dept.id }
                                                    )
                                                })
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('department')}
                                            />
                                        </div>
                                        {errors.department && <Alert severity="error">{errors.department}</Alert>}
                                    </div>
                                    {/* Position  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Position <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {positions.map(item => {
                                                    return (
                                                        { label: item.name, value: item.id }
                                                    )
                                                })
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('position')}
                                            />
                                        </div>
                                        {errors.position && <Alert severity="error">{errors.position}</Alert>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Application Deadline <RequiredAsterisk/></label>
                                            <input type="date" className="form-control atb-input-box" min={minimumDadeline()} onChange={handleChange('job_deadline')} required />
                                        </div>
                                        {errors.job_deadline && <Alert severity="error">{errors.job_deadline}</Alert>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Specific Gender</label>
                                            <Select
                                                options=
                                                {genders.map(item => {
                                                    return (
                                                        { label: item.name, value: item.id }
                                                    )
                                                })
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('gender')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Job Description <RequiredAsterisk/></label>

                                            {/* Text Editor advanced */}
                                            <ReactQuill
                                                placeholder="Enter job description"
                                                onChange={(description) => setJobDescription(description)}
                                            />

                                        </div>
                                        {errors.job_description && <Alert severity="error">{errors.job_description}</Alert>}
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Job Requirements <RequiredAsterisk/></label>

                                            {/* Text Editor advanced */}
                                            <ReactQuill
                                                placeholder="Enter job requirements"
                                                onChange={(requirement) => setJobRequirement(requirement)}
                                            />
                                        </div>
                                        {errors.job_requirement && <Alert severity="error">{errors.job_requirement}</Alert>}
                                    </div>
                                    <div className='col-lg-12 col-md-12 m-b30 m-t30'>
                                        <h5 className="font-weight-700 pull-left text-uppercase">Details Information</h5> <hr />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <h6>Academic Qualification</h6>
                                        <hr className='d-lg-none' />
                                        <div className="form-group">
                                            <label>Minimum <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {qualifications ? qualifications.map(item => {
                                                    return (
                                                        { label: item.name, value: item.id }
                                                    )
                                                }) : " "
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('qualification')}
                                                required />
                                        </div>
                                        {errors.qualification && <Alert severity="error">{errors.qualification}</Alert>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <h6 style={{ visibility: "hidden" }}> Academic Qualification</h6>
                                        <div className="form-group">
                                            <label>Prefer</label>
                                            <Select
                                                options=
                                                {qualifications ? qualifications.map(item => {
                                                    return (
                                                        { label: item.name, value: item.id }
                                                    )
                                                }) : " "
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('qualification_prefer')}
                                            />
                                        </div>
                                    </div>
                                    {/* Job Type  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Job Type <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {types ? types.map(item => {
                                                    return (
                                                        { label: item.name, value: item.id }
                                                    )
                                                }) : " "
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('job_type')}
                                                required />
                                        </div>
                                        {errors.job_type && <Alert severity="error">{errors.job_type}</Alert>}
                                    </div>

                                    {/* Salary Type  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Salary Type <RequiredAsterisk/></label>
                                            {cxoJob ? 
                                                <Select
                                                options={cxoOption}
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('salary_type')}
                                                required />
                                                :
                                                <Select
                                                options=
                                                {salary ? salary.map(item => {
                                                    return (
                                                        { label: item.type, value: item.id }
                                                    )
                                                }) : " "
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('salary_type')}
                                                required />
                                            }
                                        </div>
                                        {errors.salary_type && <Alert severity="error">{errors.salary_type}</Alert>}
                                    </div>

                                    <div className='col-lg-12 col-md-12'>
                                        <div className='row'>
                                            {/* Minimum Salary  */}
                                            <div className="col-lg-4 col-md-4">
                                                <h6>Salary Range</h6>
                                                <hr className='d-lg-none' />
                                                <div className="form-group">
                                                    <label>Minimum <RequiredAsterisk/></label>
                                                    <input type="number" min="0" disabled={negotiable} onChange={handleChange('minimum_salary')} className="form-control atb-input-box mb-4" placeholder="e.g. 10000" />
                                                    {errors.minimum_salary && <Alert severity="error">{errors.minimum_salary}</Alert>}
                                                    <div className="custom-control custom-checkbox m-t10">
                                                        <input onChange={(e) => setNegotiable(e.target.checked)} type="checkbox" className="custom-control-input" id={`negotiable`} name="negotiable" />
                                                        <label className="custom-control-label" htmlFor={`negotiable`}>{'Negotiable'}</label>
                                                        {errors.negotiable && <Alert severity="error" sx={{marginLeft: "-24px", marginTop:'4px'}}>{errors.negotiable}</Alert>}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Maximum Salary  */}
                                            <div className="col-lg-4 col-md-4">
                                                <h6 style={{ visibility: "hidden" }}>Salary Range</h6>
                                                <div className="form-group">
                                                    <label>Maximum <RequiredAsterisk/></label>
                                                    <input type="number" min="0" disabled={negotiable} onChange={handleChange('maximum_salary')} className="form-control atb-input-box" placeholder="e.g. 20000" />
                                                </div>
                                                {errors.maximum_salary && <Alert severity="error">{errors.maximum_salary}</Alert>}
                                            </div>
                                            {/* Maximum Salary  */}
                                            <div className="col-lg-4 col-md-4">
                                                <h6 style={{ visibility: "hidden" }}>Salary Range</h6>
                                                <div className="form-group">
                                                    <label>Currency</label>
                                                    {cxoJob ?
                                                        <Select
                                                        isDisabled={negotiable}
                                                        options={cxoCurrency}
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('salary_currency')}
                                                    />
                                                    :
                                                    <Select
                                                        isDisabled={negotiable}
                                                        options={currency?.map((item, index) => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('salary_currency')}
                                                    />
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Minimum Experience  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Minimum Experience <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {experience.map(exp => {
                                                    return (
                                                        { label: exp + " Year", value: exp }
                                                    )
                                                })
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('minimum_experience')}
                                            />
                                        </div>
                                        {errors.minimum_experience && <Alert severity="error">{errors.minimum_experience}</Alert>}
                                    </div>

                                    {/*Maximum Experience  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Maximum Experience <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {experience.map(exp => {
                                                    return (
                                                        { label: exp + " Year", value: exp }
                                                    )
                                                })
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('maximum_experience')}
                                            />
                                        </div>
                                        {errors.maximum_experience && <Alert severity="error">{errors.maximum_experience}</Alert>}
                                    </div>

                                    {/* Shift  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Job Shift <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {shifts ? shifts.map(item => {
                                                    return (
                                                        { label: item.name, value: item.id }
                                                    )
                                                }) : " "
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('job_shift')}
                                                required />
                                        </div>
                                        {errors.job_shift && <Alert severity="error">{errors.job_shift}</Alert>}
                                    </div>
                                    {/* Level  */}
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Job Level <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {levels ? levels.map(item => {
                                                    return (
                                                        { label: item.candidate_level_name, value: item.id }
                                                    )
                                                }) : " "
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('job_level')}
                                                required />
                                        </div>
                                        {errors.job_level && <Alert severity="error">{errors.job_level}</Alert>}
                                    </div>
                                    {/* Skill  */}
                                    <div className='d-flex justify-content-center align-items-center col-lg-12'>
                                    <div className="w-50">
                                        <div className="form-group">
                                            <label>Job Skills <RequiredAsterisk/></label>
                                            <Select
                                                options={skillsOption}
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleSkillChange}
                                                value={selectedSkill}
                                                required  isClearable/>
                                        </div>
                                       
                                    </div>
                                    <div className="w-50 ml-4 mr-3">
                                        <div className="form-group">
                                            <label>Years of Experience</label>
                                            <input 
                                               className='form-control atb-input-box' 
                                               type="number"
                                               step='.01'
                                               min='0'
                                               max='100'
                                               placeholder='Number of Years' 
                                               onKeyDown={(e) => handleKeyDown(e)}
                                               value={yearsOfExperience}
                                               onChange={(e) => setYearsOfExperience(e.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <Button
                                      variant="outlined"
                                      fullWidth
                                      sx={{
                                        paddingY: "9px",
                                        backgroundColor: "#CBE7FF",
                                        maxWidth: "100px"
                                      }}
                                      onClick={addValueToArray}
                                    >
                                      Add New
                                    </Button>
                                    </div>
                                    <div className='col-12'>
                                    
                                        <Box sx={{display: "flex", flexWrap: 'wrap'}}>
                                        {selectedSkillArry ? selectedSkillArry?.map((item, index) => 
                                            <Box 
                                            key={index} 
                                            sx={{display: "flex", alignItems: "center", mr: 1, my: 0.5, p: 0.5, border: "1px solid #E5E5E5"}}
                                            >
                                                <Box sx={{display: "flex", alignItems: "center"}}>
                                                    <Typography>{item.skill_name}</Typography>
                                                    <Typography> - {item.year_of_experience} {item.year_of_experience > 1 ? "Years" : "Year"}</Typography>
                                                </Box>
                                                    <Box sx={{ mb: 2}} onClick={() => handleDelete(item.skill_id)}><DeleteSVG /></Box>
                                            </Box>
                                             ) : null}
                                        </Box>
                                        {errors.job_skill ? <Alert severity="error">{errors.job_skill}</Alert> : null}
                                        {errors.skill_exists ? <Alert severity="error">{errors.skill_exists}</Alert> : null}
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <label>Number of Vacancy <RequiredAsterisk/></label>
                                            <input className='form-control atb-input-box' disabled={notSpecific} type="number" onChange={handleChange('number_of_vacancy')} placeholder='Number of Vacant Positions' />
                                            <div className="custom-control custom-checkbox m-t10">
                                                <input onChange={(e) => setNotSpecific(e.target.checked)} type="checkbox" className="custom-control-input" id={`not_specific`} name="not_specific" />
                                                <label className="custom-control-label" htmlFor={`not_specific`}>{'Not Specific'}</label>
                                            </div>
                                        </div>
                                        {errors.number_of_vacancy && <Alert severity="error">{errors.number_of_vacancy}</Alert>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Address <RequiredAsterisk/></label>
                                            <textarea type="text" className="form-control atb-input-box" onChange={handleChange('job_address')} placeholder="Address" />
                                        </div>
                                        {errors.job_address && <Alert severity="error">{errors.job_address}</Alert>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Location <RequiredAsterisk/></label>
                                            <Select
                                                options=
                                                {district.map(ind => {
                                                    return (
                                                        { label: ind.name, value: ind.id }
                                                    )
                                                })
                                                }
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleChangeSelect('district_id')}
                                            />
                                        </div>
                                        {errors.district_id && <Alert severity="error">{errors.district_id}</Alert>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Minimum Age</label>
                                            <input type="number" className="form-control atb-input-box" onChange={handleChange('age_from')} placeholder="Minimum Age" />
                                        </div>
                                        {errors.age_from && <Alert severity="error">{errors.age_from}</Alert>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Maximum Age</label>
                                            <input type="number" className="form-control atb-input-box" onChange={handleChange('age_to')} placeholder="Maximum Age" />
                                        </div>
                                        {errors.age_to && <Alert severity="error">{errors.age_to}</Alert>}
                                    </div>
                                    <div className='col-lg-12 col-md-12 m-b20 m-t30'>
                                        <h5 className="font-weight-700 pull-left text-uppercase">Job Benefits</h5> <hr />
                                    </div>

                                    <div className='col-lg-6 col-md-6' style={{ borderRight: '1px solid grey' }}>
                                        <div class=" bg-white">
                                            <ul class="list-unstyled ml-2">
                                                {
                                                    jobBenefitCats?.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div class="collapse show" id={`${index}-collapse`}>
                                                                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small mb-3">
                                                                        <li >
                                                                            <h5 className='font-weight-200'>{item.title}</h5>
                                                                            <ul>
                                                                                {item.child ? item.child.map((child, childIndex) => {
                                                                                    return (
                                                                                        <li className='ml-4'>
                                                                                            <Link key={childIndex} style={currentBenefit == child.id ? { marginLeft: '20px', color: 'black', borderBottom: '1px solid blue' } : { marginLeft: '5px' }} to="#" onClick={() => setCurrentBenefit(child.id)} class="link-dark text-dark">{child.title}</Link>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                                    : " "
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6' >
                                        <div class=" bg-white">
                                            <ul class="list-unstyled mt-1">
                                                {
                                                    jobBenefitCatOptns?.map((item, index) => {
                                                        return (
                                                            <div className="custom-control custom-checkbox" key={index}>
                                                                <input onChange={(e) => {
                                                                    // add to list
                                                                    if (e.target.checked) {
                                                                        setCheckedBenefitList([...checkedBenefitList, item.id]);
                                                                    }
                                                                    else {
                                                                        // remove from list
                                                                        setCheckedBenefitList(
                                                                            checkedBenefitList.filter((s) => s !== item.id),
                                                                        );
                                                                    }
                                                                }}
                                                                    checked={defaultSelectedCheckBox(item)}
                                                                    value={item.id} type="checkbox" className="custom-control-input" id={`benefit-check${index}`} name="job_benefit" />
                                                                <label className="custom-control-label" htmlFor={`benefit-check${index}`}>{item.title}</label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='col-lg-12 col-md-12 m-t30'>
                                        <h5 className="font-weight-700 pull-left text-uppercase">Custom Questions</h5> <hr />
                                    </div>

                                    {/* {questionList.map((question, index) => {
                                        return (
                                            <>
                                                <div className="col-lg-12 col-md-12">
                                                    <label>Question {index + 1}</label>
                                                </div>
                                                <div className="col-lg-10 col-md-10">
                                                    <div className="form-group">
                                                        <input type="text" defaultValue={question.custom_question} className="form-control atb-input-box" onChange={handleChangeCustomQuestion('custom_question', index)}  placeholder="Enter your question" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6" style={{ marginTop: '0.25em' }}>
                                                                {questionList.length - 1 === index && <i className="fa fa-plus-square-o fa-2x d-none d-lg-block" aria-hidden="true" onClick={handleAddClick}></i>}
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                {questionList.length !== 1 && <i className="fa fa-trash-o fa-2x d-none d-lg-block" aria-hidden="true" onClick={() => handleRemoveClick(index)}></i>}
                                                            </div>
                                                        </div>

                                                        <div className='d-lg-none'>
                                                            {questionList.length - 1 === index && <button className="btn btn-outline-primary btn-md btn-block" aria-hidden="true" onClick={handleAddClick}>Add</button>}
                                                            {questionList.length !== 1 && <button className="btn btn-danger btn-md btn-block" aria-hidden="true" onClick={() => handleRemoveClick(index)}>Remove</button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })} */}
                                </div>
                                {/* <div className='row m-b20'>
                                    <div className='col-12 m-b20'>
                                        <Chip label="How soon are you available to start?" sx={{ marginRight: '10px' }}
                                            onClick={() => [addSuggestCustomQuestion('How soon are you available to start?'), setDisableCustomQuesAddButton1(true)]}
                                            disabled={disableCustomQuesAddButton1}
                                            variant="outlined" />
                                        <Chip label="Do you have IELTS score?"
                                            onClick={() => [addSuggestCustomQuestion('Do you have IELTS score?'), setDisableCustomQuesAddButton2(true)]}
                                            disabled={disableCustomQuesAddButton2}
                                            variant="outlined" />
                                    </div>
                                    {
                                        customQuestionArray?.map((item, index) => {
                                            return (
                                                <>
                                                    <div key={item} className='col-10 m-b10'>
                                                        <label>Question {index + 1}</label>
                                                        <input type='text' className='form-control' onBlur={(e) => updateCustomQuArray(index, e)} defaultValue={item} />
                                                    </div>
                                                    <div className='col-2'>
                                                        <label style={{ visibility: 'hidden' }}>Button</label> <br />
                                                        <Button onClick={() => deleteCustomQuestion(index)}>
                                                            <i className="fa fa-trash-o fa-2x d-none d-lg-block" aria-hidden="true" ></i>
                                                        </Button>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    <div className='col-10 m-t20'>
                                        <form id='custom-question-add'>
                                            <label>Add Question</label>
                                            <input type='text' className='form-control' placeholder='Enter your question' onChange={(e) => setTempQuestion(e.target.value)} />
                                        </form>
                                    </div>
                                    <div className='col-2 m-t20'>
                                        <label style={{ visibility: 'hidden' }}>Button</label> <br />
                                        <Button onClick={() => addCustomQuestion()} disabled={tempQuestion === "" ? true : false} ><i className="fa fa-plus-square-o fa-2x d-none d-lg-block" aria-hidden="true"></i></Button>
                                    </div>
                                </div> */}
                                <JobPostCustomQuestions customQuestionArray={customQuestionArray} setCustomQuestionArray={setCustomQuestionArray} errors={errors} />
                                <div className='text-right d-none d-lg-block'>
                                    <LoadingButton
                                        loading={jobPostLoader}
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            marginBottom: '30px',
                                            marginRight: '30px',
                                            padding: '10px',
                                            '&:hover': {
                                                backgroundColor: '#1965b1',
                                            },
                                            textTransform: 'capitalize'
                                        }}
                                        className="site-button m-b30 m-r30"
                                        onClick={() => validateDraftJob(jobData)}
                                    >
                                        Save
                                    </LoadingButton>

                                    <button type="button" className="site-button m-b30" onClick={() => [setJobData({ ...jobData, job_status: 'published' }), setPublishButton(true), validatePostJob(jobData)]}>Publish</button>
                                </div>

                                <div className='row d-lg-none'>
                                    <div className='col'>
                                        <LoadingButton loading={jobPostLoader} type="button" variant="Outlines" color="primary" sx={{ marginBottom: '30px', marginRight: '30px', padding: '10px' }} className="site-button btn-block m-b30 m-r30" onClick={() => validateDraftJob(jobData)}>Save</LoadingButton>
                                    </div>
                                    <div className='col'>
                                        <button type="button" className="site-button btn-block m-b30" onClick={() => [setJobData({ ...jobData, job_status: 'published' }), setPublishButton(true), validatePostJob(jobData)]}>Publish</button>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>

                    {/* Confirmation Modal */}
                    <Modal show={confirmationModal} onHide={setConfirmationModal} className="modal fade modal-bx-info editor" >
                        <div className="modal-dialog my-0" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="EmploymentModalLongTitle">Confirm?</h5>
                                    <button type="button" className="close" onClick={() => setConfirmationModal(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>{confirmationMessage}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <p>You can edit this job from your dashboard.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">

                                    <LoadingButton
                                        onClick={() => [setPublishButton(false), setConfirmationModal(false), setJobPostLoader(false)]}
                                        variant="outlined"
                                        endIcon={<CancelPresentation />}
                                        loadingPosition="end"
                                        loading={jobPostLoader}
                                    >
                                        Cancel
                                    </LoadingButton>
                                    {publishButton ?
                                        <LoadingButton
                                            onClick={() => [postJobFunction(jobData), setJobPostLoader(true)]}
                                            loading={jobPostLoader}
                                            variant="outlined"
                                            endIcon={<PublishOutlined />}
                                            loadingPosition="end"
                                        >
                                            Publish
                                        </LoadingButton>
                                        :
                                        <button type="button" className="site-button" onClick={() => saveJob(jobData)} >Save</button>

                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <SuccessAlert open={successModal} message={sucessMessage} onHide={() => setSuccessModal(false)} />

                </div>
            </div>
        </div>
    )
}

export default EmployerPostJob