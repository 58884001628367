import React, { useContext, useEffect, useRef, useState } from "react";
import {
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Divider,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
  Skeleton,
  // Select,
  OutlinedInput,
} from "@mui/material";
import Select from 'react-select'
import { LoadingButton } from "@mui/lab";
import { DropdownContext } from "../../../context/DropdownContext";
import { candidateRegisterFlow, stepperStepInfo } from "../../../../fetchAPI";
import { fetchIpAddress, handleKeyDown } from "../../../helpers/helperFunctions";
import { useAlert } from "../../../context/AlertContext";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M0 18.4731C0.438118 16.7379 0.874932 15.002 1.31044 13.2655C1.35415 13.0819 1.4491 12.9145 1.58427 12.7828C3.95349 10.4209 6.31685 8.05581 8.67435 5.68743C10.5259 3.84145 12.3736 1.99156 14.2173 0.137764C14.3992 -0.0459215 14.3992 -0.0459215 14.5831 0.137764L18.3208 3.87597C18.3794 3.93655 18.444 3.99322 18.5164 4.05185V4.12609L18.3442 4.29024L5.69162 16.9431C5.59254 17.0497 5.46492 17.1256 5.32392 17.1619C4.48484 17.3573 3.64968 17.5743 2.81256 17.7833L0.111485 18.4653L0 18.4731ZM2.83799 15.7374L2.94556 15.71C3.37781 15.6026 3.81006 15.4873 4.24427 15.3915C4.41728 15.3545 4.57529 15.2668 4.69803 15.1394C5.93546 13.8888 7.17418 12.6408 8.41421 11.3954C10.8043 9.01396 13.1911 6.63387 15.5747 4.25507C15.6999 4.13001 15.7018 4.05965 15.5747 3.93459C15.2266 3.60239 14.8843 3.26043 14.5518 2.91064C14.4305 2.78363 14.3562 2.7973 14.2467 2.91064C13.3795 3.79781 12.5085 4.67519 11.6336 5.54282C8.86798 8.31244 6.1004 11.0795 3.33087 13.8439C3.22469 13.9444 3.15111 14.0744 3.11963 14.2171C3.04531 14.5513 2.95534 14.8815 2.87124 15.2117C2.83603 15.3544 2.79887 15.497 2.76562 15.6397C2.75193 15.6983 2.74802 15.755 2.83799 15.7413V15.7374Z"
        fill="#0275D8"
      />
    </svg>
  );
}

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M4.5 6V15C4.5 15.8284 5.17157 16.5 6 16.5H12C12.8284 16.5 13.5 15.8284 13.5 15V6H4.5Z"
        fill="#DB1616"
      />
      <path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 3.375V3.25C10.5 2.69772 10.0523 2.25 9.5 2.25H8.5C7.94772 2.25 7.5 2.69772 7.5 3.25V3.375H4.25C3.97386 3.375 3.75 3.59886 3.75 3.875V4C3.75 4.27614 3.97386 4.5 4.25 4.5H13.75C14.0261 4.5 14.25 4.27614 14.25 4V3.875C14.25 3.59886 14.0261 3.375 13.75 3.375H10.5Z"
        fill="#DB1616"
      />
    </svg>
  );
}

const customStyles = {
  control: (base) => ({
    ...base,
    padding: "3px",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#A2A2A2",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px", 
    overflowY: "auto",
  }),
};

export default function SkillInformation({ handleBack, handleNext, job_id, candidateData, settingCandidateData, candLoader }) {
  const {
    skills,
    setApiCallingConditionSet,
    apiCallingConditionSet,
  } = useContext(DropdownContext);
  const [tempSkillArray, setTempSkillArray] = useState([]);
  const [tempSkill, setTempSkill] = useState({ id: "" });
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [skillError, setSkillError] = useState(false);
  const alertContext = useAlert();
  const [saveButtonHoverEffect, setSaveButtonHoverEffect] = useState("outlined");


  useEffect(() => {
    setApiCallingConditionSet({
      ...apiCallingConditionSet,
      skill: true,
    });
  }, []);

  useEffect(() => {
    setTempSkillArray(candidateData?.skills);
  }, [candidateData]);

  const addValueToArray = async () => {
    const isExists = tempSkillArray?.find((skill) => skill.id == tempSkill.id)
    if(skillError){
      const newArray = [];
      setTempSkillArray(newArray);
    }else{
      if(!isExists){
        const newArray = [...tempSkillArray, tempSkill];
        setTempSkillArray(newArray);
      }else{
        setResponseMessage([`${isExists.skill_name} is already added.`])
      }
      }
  };

  const removeSkillFromTemp = (index) => {
    const newArray = [...tempSkillArray];
    newArray.splice(index, 1);
    setTempSkillArray(newArray);
  };

  const handleAddSkill = () => {
    setLoading(true);
    candidateRegisterFlow("skill", job_id, tempSkillArray)
      .then((response) => {
        if (response && response.success) {
          handleNext();
        } else {
          setResponseMessage(response.errors[0]?.message);
        }
      })
      .finally(() => setLoading(false), setResponseMessage(""));

      const ip = fetchIpAddress();
      const platform = "web";
      const stepNo = 4;
  
      stepperStepInfo(stepNo, ip, platform).then((response) => {
        if(response && response.success) {
          setLoading(false);
        }
      })
  };

  const handleSkillSubmit = () => {
      addValueToArray()
      .then(() =>       
        setTempSkill({
        id: "",
        skill_name: "",
        pivot: { year_of_experience: "" }
    }));

  };

  const formatSkills = (data) => {
    return data?.map(skill => ({
        label: skill.skill_name,
        value: skill.id
    }));
}
const skillsOption = formatSkills(skills);

  useEffect(() => {
    if(
      tempSkill?.pivot?.year_of_experience > 50 || 
      tempSkill?.pivot?.year_of_experience < 0
    ){
      setSkillError(true);
    }else{
      setSkillError(false) 
    }
  }, [tempSkill?.pivot?.year_of_experience]);

  return (
    <>
      {responseMessage ? (
        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
          {responseMessage?.map((item, index) => {
            return (
              <center key={index}>
                <small style={{ color: "red" }}> {responseMessage} </small>
              </center>
            );
          })}
        </div>
      ) : (
        ""
      )}
      {candLoader ? (
        <div className="mt-3 col-lg-12 col-md-12">
          <Skeleton variant="text" height={60} />
          <Skeleton variant="text" height={50} />
          <div className="text-left">
            <Skeleton variant="text" />
          </div>
          <div className="mt-3 text-left">
            <Skeleton variant="rounded" height={120} />
          </div>
          <div className="text-left">
            <Skeleton variant="text" width={80} height={50} />
          </div>
        </div>
      ) : (
        <>
        <form
        className="ga_stepper_candidate_register_skill_info_form" // do not remove ga_ class
        id="skill-insert-form"
        onSubmit={(e) => [e.preventDefault(), handleSkillSubmit()]}
      >
          <Box
            sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
          >
            <Box sx={{ width: "100%", mr: 1 }}>
              <Typography align="left" variant="h6" sx={{ fontWeight: 600 }}>
                Key Skills
              </Typography>
            </Box>
            <Box sx={{ minWidth: 85, margin: 1 }}>
              <Button
                size="small"
                variant="outlined"
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "#CBE7FF",
                }}
                // onClick={() => addValueToArray().then(() => resetValues())}
              >
                Add New
              </Button>
            </Box>
          </Box>
          <Divider />
          <Grid container spacing={2} mt={3} sx={{ display: {lg: "flex"} }}>
            <Grid item lg={5} xs={12} sx={{marginTop: {xs: 5, sm: 5, lg: 0}}}>
            <div className=" w-full">
                  <label style={{color: "#666666"}}>Skills</label>
                  <Select
                      options={skillsOption}
                      styles={customStyles}
                      placeholder="Select Skills"
                      onChange={(selected) => 
                       { if (selected) {
                          setTempSkill({
                            ...tempSkill,
                            id: selected.value,
                            skill_name: selected.label
                          });
                        } else {
                          setTempSkill({ id: "", skill_name: "" });
                        }}
                      }
                      value={skillsOption.find(option => option.value === tempSkill.id) || null}
                      required  isClearable/>
              </div>
            </Grid>
            <Grid item lg={5} xs={12}>
              <InputLabel sx={{ fontWeight: "bold" }}>
                Years of Experience
              </InputLabel>
              <TextField
                required
                type="number"
                variant="outlined"
                placeholder="Number of Years"
                fullWidth
                error={skillError}
                value={tempSkill?.pivot?.year_of_experience}
                onChange={(e) =>
                    setTempSkill({
                      ...tempSkill,
                      pivot: { year_of_experience: e.target.value },
                    })
                }
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                  onKeyDown: (e) => handleKeyDown(e),
                }}
              />
              <InputLabel sx={{fontSize: "13px", color: "red", pt: 0.5}}>{skillError ? "Years of experience should between 0 to 50" : null}</InputLabel>
            </Grid>
            <Grid item lg={2} xs={12}>
              <Box
                sx={{
                  marginBottom: 2,
                  marginTop: 2.5,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    paddingY: "9px",
                    backgroundColor: "#CBE7FF",
                  }}
                  // onClick={() => addValueToArray().then(() => resetValues())}
                  type="submit"
                >
                  Add New
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Divider />
          </form>
          {tempSkillArray?.map((item, index) => {
            return (
              <Box m={2} key={index}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <Stack spacing={1}>
                      <Typography variant="h6" component="body1">
                        {item.skill_name}
                      </Typography>
                      <Typography variant="caption">
                        {item.pivot?.year_of_experience} Years
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <IconButton
                      aria-label="download"
                      sx={{
                        backgroundColor: "#F3F6F9",
                      }}
                      onClick={() => [
                        setTempSkill(item),
                        removeSkillFromTemp(index),
                      ]}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <IconButton
                      aria-label="download"
                      sx={{
                        backgroundColor: "#F3F6F9",
                        marginLeft: 1,
                      }}
                      onClick={() => removeSkillFromTemp(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            );
          })}
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 4 }}>
            <Button
              variant="outlined"
              // disabled
              sx={{ mr: 1, minWidth: {sm: "102px"} }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Box sx={{ display: "flex" }}>
              <LoadingButton
                sx={{ ml: {xs: 1, md: 2, minWidth: {sm: "102px"}} }}
                variant="contained"
                loading={loading}
                onClick={handleAddSkill}
              >
                Continue
              </LoadingButton>
            </Box>
          </Box>
          </>
      )}
    </>
  );
}
