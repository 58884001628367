import React, { useContext, useEffect } from "react";
import { Grid, Stack, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Typography } from "@mui/material";
import { JobSearchContext } from "../../context/JobSearchContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import _ from 'lodash';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './BenefitSearchComponent.css'
import GlobalLoader from "../GlobalLoader";

SwiperCore.use([Navigation]);

export default function EmployerBenefits() {

    const { employerBenefitList, checkedEmpBenefitList, setCheckedEmpBenefitList, isLoading } = useContext(JobSearchContext)

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setCheckedEmpBenefitList([...checkedEmpBenefitList, id]);
        } else {
            setCheckedEmpBenefitList(
                checkedEmpBenefitList.filter((s) => s !== id)
            );
        }
    };

    const defaultSelectedEmpCheckBox = (id) => {
        if (checkedEmpBenefitList && checkedEmpBenefitList.length > 0) {
            return checkedEmpBenefitList.includes(id);
        }
        return false;
    };


    return (
        <>
            <Swiper
                spaceBetween={10}
                slidesPerView={4}
                breakpoints={{
                    360: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
            >
                {
                    isLoading ?
                        <GlobalLoader />
                        :
                        employerBenefitList.map((benefit, index) => {
                            // Divide the options into chunks, e.g., 5 items per slide
                            const chunks = _.chunk(benefit.options, 5);

                            const name = benefit.category_name;

                            return chunks.map((chunk, chunkIndex) => (
                                <SwiperSlide
                                    key={`${index}-${chunkIndex}`}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Stack spacing={2} sx={{ width: '100%' }}>
                                        {/* Display category name only on the first chunk of each category */}
                                        {chunkIndex === 0 && (
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    color: '#424447',
                                                    width: '100%',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {benefit.category_name}
                                            </Typography>
                                        )}
                                        <Grid
                                            container
                                            spacing={0}
                                            flexDirection={'column'}
                                            sx={{
                                                width: '100%',
                                                flexWrap: 'nowrap',
                                                marginTop: name == benefit.category_name && chunkIndex == 0 ? '16px !important' : '36px !important'
                                            }}
                                        >
                                            {
                                                chunk.map((option, idx) => (
                                                    <Grid key={idx} item sx={{ width: '100%' }}>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={defaultSelectedEmpCheckBox(option.id)}
                                                                        onChange={(event) => handleCheckboxChange(event, option.id)}
                                                                        sx={{
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0,
                                                                        }}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{
                                                                            fontSize: '12px',
                                                                            color: '#424447',
                                                                            paddingLeft: 1,
                                                                            width: '100%',
                                                                            textAlign: 'left',
                                                                        }}
                                                                    >
                                                                        {option.title}
                                                                    </Typography>
                                                                }
                                                                sx={{
                                                                    paddingRight: '10px',
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Stack>
                                </SwiperSlide>
                            ));
                        })
                }
            </Swiper>
        </>
    );
}