import { Alert, Button } from "@mui/material";
import React, { useState } from "react";
import { deleteCustomQuestion, deleteJobCustomQuestionOption } from "../../../../fetchAPI";
import { useParams } from 'react-router-dom';
import { useAlert } from "../../../context/AlertContext";
import { useDeleteAlert } from "../../../context/DeleteConfirmationModalContext";
import RequiredAsterisk from "../../../components/CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk";

export default function JobPosstCustomQuestionUpdate({ customQuestionArray, setCustomQuestionArray, setErrors, errors }) {

    const [tempQuestion, setTempQuestion] = useState('')
    const { job_id } = useParams()
    const alertContext = useAlert();
    const deleteAlertContext = useDeleteAlert();

    const addCustomQuestion = () => {
        setCustomQuestionArray([...customQuestionArray, { question_id: '', question: tempQuestion, options: [] }])
        setTempQuestion('')
        document.getElementById('custom-question-add').reset()
    }
    const updateCustomQuArray = (index, e, questionId) => {
        const list = [...customQuestionArray]
        list[index] = { question_id: questionId ? questionId : '', question: e.target.value }
        setCustomQuestionArray(list)
    }

    const removeCustomQuestion = (index, questionId) => {
        // console.log(index, questionId)
        if (questionId != '') {
            deleteCustomQuestion(job_id, questionId).then(response => {
                if (response.success) {
                    const newList = customQuestionArray.filter((_, i) => i != index)
                    setCustomQuestionArray(newList)
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                } else {
                    setErrors(response.errors[0].message);
                    alertContext.setResponseMessage(response.errors[0].message);
                    alertContext.setOpenErrorAlert(true)

                }
            })
        } else {
            const newList = customQuestionArray.filter((_, i) => i != index)
            setCustomQuestionArray(newList)
        }
    }

    const deleteQuestionModal = (index, questionId) =>
    {
        deleteAlertContext.setDeleteFunction (() => () => {
            removeCustomQuestion(index, questionId);
        });
        deleteAlertContext.setOpen(true);
    }

    const removeCustomQuestionOption = (index, optionIndex) => {
        let optionId = customQuestionArray[index]['options'][optionIndex].id;
        console.log(optionId);
        if (optionId == '') {
            const list = [...customQuestionArray];
            list[index]['options'].splice(optionIndex, 1);
            setCustomQuestionArray(list);
        } else {

            deleteJobCustomQuestionOption(optionId).then(response => {
                if (response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true);

                    const list = [...customQuestionArray];
                    list[index]['options'].splice(optionIndex, 1);
                    setCustomQuestionArray(list);
                } else {
                    alertContext.setResponseMessage(response.errors[0].message);
                    alertContext.setOpenErrorAlert(true)
                }
            })
        }
    }

    const deleteOptionModal = (index, optionIndex) =>
    {
        deleteAlertContext.setDeleteFunction (() => () => {
            removeCustomQuestionOption(index, optionIndex);
        });
        deleteAlertContext.setOpen(true);
    }

    const updateCustomQuestionsOptions = (index, optionIndex, e) => {
        const list = [...customQuestionArray]
        list[index]['options'][optionIndex]['option'] = e.target.value
        setCustomQuestionArray(list)
    }

    const updateCustomQuestionsOptionsCount = (index, e) => {
        const list = [...customQuestionArray]
        list[index]['noOfOptions'] = e.target.value
        setCustomQuestionArray(list)
    }

    const createOptionsInput = (index) => {
        const list = [...customQuestionArray]
        const noOptionToAdd = list[index]['noOfOptions']
        list[index]['options'] = list[index]['options'].filter(item => item.id != "")
        for (let i = 0; i < noOptionToAdd; i++) {
            list[index]['options'].push({ option: "", id: "" })
        }
        setCustomQuestionArray(list)
    }

    return (
        <div>
            {
                customQuestionArray?.map((item, index) => {
                    return (
                        <div className="shadow-sm p-3 mb-4 col-12" key={index} style={{ border: '1px solid #c7c7c775', borderRadius: '5px' }}>
                            <div className="row mb-3">
                                <div key={item.question} className='col-10 m-b10'>
                                    <label>Question {index + 1}</label>
                                    <input type='text' className='form-control' onChange={(e) => updateCustomQuArray(index, e, item.question_id)} value={item.question} />
                                </div>
                                <div className='col-2'>
                                    <Button onClick={() => deleteQuestionModal(index, item.question_id)}>
                                        <i className="fa fa-trash-o fa-2x" style={{ color: '#db1313' }} aria-hidden="true" ></i>
                                    </Button>
                                </div>
                            </div>

                            <div className="col-12 mt-4">
                                <label>New Options: <RequiredAsterisk /> </label>
                                <div className="row" style={{ margin: 'unset' }}>
                                    <input type='number' max={20} className='form-control col-10' placeholder="Type options number" value={item?.noOfOptions == undefined ? "" : item?.noOfOptions} onChange={(e) => updateCustomQuestionsOptionsCount(index, e)} />
                                    <Button variant="outlined" className="col-2" onClick={() => createOptionsInput(index)}>Add</Button>
                                    <span className="mt-2">
                                            {errors.customQuestion && <Alert severity="error">{errors.customQuestion}</Alert>}
                                    </span>  
                                </div>
                            </div>

                            {
                                item?.options?.map((optionItem, optionIndex) => {
                                    return (
                                        <div className="mb-2 row">
                                            <div className="col-10">
                                                <label>Option {optionIndex + 1} <RequiredAsterisk /> </label>
                                                <input className="form-control" type="text" value={optionItem.option} onChange={(e) => updateCustomQuestionsOptions(index, optionIndex, e)} />
                                                <div className="mt-2">
                                                    {errors.customQuestion && <Alert severity="error">{errors.customQuestion}</Alert>}
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <Button sx={{ marginTop: '28px' }} onClick={() => deleteOptionModal(index, optionIndex)}>
                                                    <i className="fa fa-trash-o" style={{ fontSize: '20px', color: '#db1313' }} aria-hidden="true" ></i>
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
            <div className="row">
                <div className='col-10 m-b20 m-t20'>
                    {/* <hr /> */}
                    <form id='custom-question-add'>
                        <label>Add Question</label>
                        <input type='text' className='form-control' placeholder='Enter your question' onChange={(e) => setTempQuestion(e.target.value)} />
                    </form>
                </div>
                <div className='col-2 m-b20 m-t20'>
                    <label style={{ visibility: 'hidden' }}>Button</label> <br />
                    <Button onClick={() => addCustomQuestion()} disabled={tempQuestion === "" ? true : false} ><i className="fa fa-plus-square-o fa-2x d-none d-lg-block" aria-hidden="true"></i></Button>
                </div>
            </div>
        </div>
    )
}