import React, { useState, useEffect, useContext, useRef } from 'react'
import { EmployerContext } from '../../../context/EmployerContext';
import { updateEmployerByID, getSpecialization, updateEmployerSocialNetwork, deleteEmployerSocialNetworkByID, postEmployerSocialNetworkByID, getEmployerBenefits, getEmployerBenefitOptions, insertEmployerTeam, insertEmployerGallery, insertEmployerContent, getEmployerContentTitle, deleteTeamMembers, getUpazillas, getAreas } from '../../../../fetchAPI';
import Select from 'react-select'
import JoditEditor from 'jodit-react';
import JoditReact from 'jodit-react-ts';
import { Alert, Button } from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';
import SuccessAlert from '../../Alerts/SuccessAlert'
import { DropdownContext } from '../../../context/DropdownContext';
import { Link } from 'react-router-dom'
import { LoadingButton } from '@mui/lab';
import UpdateDeleteConfirmationModal from '../../CandidateComponents/CandidateProfile/UpdateProfile/UpdateDeleteConfirmationModal';
import UpdateSocialModal from './UpdateSocialModal';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { getEmployerGalleryImages, getEmployerTeamMembers, getEmployerContents, deleteEmployerGallery } from '../../../../fetchAPI';
import { Grid, Stack, Box, Avatar, Typography, Paper, Divider, Card, CardActions, CardMedia, CardContent } from '@mui/material';
import TeamMemberEdit from './UpdateModals/TeamMemberEdit';
import ContentUpdateModal from './UpdateModals/ContentUpdateModal';
import ReactHtmlParser from 'react-html-parser'
import { handleKeyDown, validatePhoneNumber } from '../../../helpers/helperFunctions';
import { useAlert } from '../../../context/AlertContext';
import UpdateOrganizationStructure from './component/UpdateOrganizationStructure';
import GlobalLoader from '../../GlobalLoader';

function UpdateEmployer() {

	const { employerData, settingEmployerData, employerDataLoading, employePreviousBenefits, setEmployePreviousBenefits } = useContext(EmployerContext)
	const { departments, industries, social, district, apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext)

	const [openAlert, setOpenAlert] = useState(false)
	const [openErrorAlert, setOpenErrorAlert] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [responseMessage, setResponseMessage] = useState('')

	const [empBenefitCats, setEmpBenefitCats] = useState([])
	const [empBenefitCatOptns, setEmpBenefitCatOptns] = useState([])
	const [currentBenefit, setCurrentBenefit] = useState(1)
	const [checkedBenefitList, setCheckedBenefitList] = useState([])

	const [loading, setLoading] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [deleteSocailId, setDeleteSocialId] = useState('')
	const [deleteSocialIndex, setDeleteSocialIndex] = useState('')
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [socialUpdateInfo, setSocialUpdateInfo] = useState('')
	const [intro, setIntro] = useState('')
	const [empSpecialization, setEmpSpecialization] = useState('')
	const [departmentList, setDepartmentList] = useState([])
	const [industryList, setIndustryList] = useState([])
	const [contentTitleList, setContentTitleList] = useState([])
	const [teamMemberLoading, setTeamMemberLoading] = useState(false)
	const [galleryLoading, setGalleryLoading] = useState(false)
	const [contentLoading, setContentLoading] = useState(false)

	const [existingGallery, setExistingGallert] = useState([])
	const [existingTeamMembers, setExistingTeamMembers] = useState([])
	const [existingContent, setExistingContent] = useState([])

	const [openMemberEditModal, setOpenMemberEditModal] = useState(false)
	const [memberEditInfo, setMemberEditInfo] = useState({})

	const [socialData, setSocialData] = useState('')
	const [upazillas, setUpazillas] = useState([])
	const [areas, setAreas] = useState([])
	const [locationDropdownSelected, setLocationDropdownSelected] = useState({
		district: false,
		upazilla: false,
		area: false
	})
	const [location, setLocation] = useState({
		district_id: '',
		district_name: '',
		upazilla_id: '',
		upazilla_name: '',
		area_id: '',
		area_name: '',
	})
	const alertContext = useAlert();

	const getGalleryImages = () => {
		getEmployerGalleryImages(employerData?.id)
			.then((response) => {
				if (response && response.success) {
					setExistingGallert(response.response.data)
				}
			})
	}

	const getTeamMembers = () => {
		getEmployerTeamMembers(employerData?.id)
			.then((response) => {
				if (response && response.success) {
					setExistingTeamMembers(response.response.data)
				}
			})
	}

	const getContents = () => {
		getEmployerContents(employerData?.id)
			.then((response) => {
				if (response && response.success) {
					setExistingContent(response.response.data)
				}
			})
	}

	useEffect(() => {
		setApiCallingConditionSet({ ...apiCallingConditionSet, department: true, industry: true, social: true, district: true })
	}, [])


	useEffect(() => {
		setLocationData()
		getGalleryImages()
		getTeamMembers()
		getContents()
	}, [employerData])

	useEffect(() => {
		getEmployerContentTitle()
			.then((response) => {
				if (response && response.success) {
					setContentTitleList(response.response.data)
				}
			})
	}, [])

	useEffect(() => {
		getEmployerBenefits()
			.then(response => {
				if (response && response.success) {
					setEmpBenefitCats(response.response.data)
				}
			})
	}, [])

	useEffect(() => {
		getEmployerBenefitOptions(currentBenefit)
			.then(response => {
				if (response && response.success) {
					setEmpBenefitCatOptns(response.response.data?.child)
				}
			})
	}, [currentBenefit])

	useEffect(() => {
		setCheckedBenefitList(employePreviousBenefits)
	}, [employePreviousBenefits])

	function defaultSelectedCheckBox(item) {
		if (checkedBenefitList.includes(item.id)) {
			return 'checked=checked'
		} else {
			return ''
		}
	}

	useEffect(() => {
		setUpdateInfo({ ...updateInfo, benefit: checkedBenefitList.toString() })
	}, [checkedBenefitList])

	let errorsObj = { headline: '' };
	const [errors, setErrors] = useState('')
	const [specializations, setSpecializations] = useState([])

	useEffect(() => {
		getSpecialization()
			.then(response => {
				if (response && response.success) {
					setSpecializations(response.response.data)
				}
				else {
					console.log("error:", response)
				}
			})
	}, [])

	useEffect(() => {
		setDepartmentList(employerData?.employer_departments?.map(item => item.department_id))
		setIndustryList(employerData?.employer_industries?.map(item => item.industry_id))
		// setDepartmentList(...departmentList, employerData?.employer_departments?.map(item=>item.department_id))
		// employerData?.employer_departments?.map(item=> setDepartmentList(...departmentList, item?.department_id))
		// employerData?.employer_industries?.map(item=> setIndustryList(...industryList, item?.industry_id))
	}, [employerData])

	const setLocationData = () => {
		setLocation({
			...location,
			district_id: employerData.district_id,
			district_name: employerData.district_name,
			upazilla_id: employerData.upazilla_id,
			upazilla_name: employerData.upazilla_name,
			area_id: employerData.area_id,
			area_name: employerData.area_name,
		})
	}

	useEffect(() => {
		setEmpSpecialization(employerData?.specialization?.map(val => val.id))
	}, [employerData])

	useEffect(() => {
		getUpazillas(location.district_id ? location.district_id : employerData.district_id).then((response) => {
			if (response && response.success) {
				setUpazillas(response.response.data)
			}
			else {
				// console.log('errors: ', response);
			}
		})
	}, [location.district_id, employerData.district_id])

	useEffect(() => {
		getAreas(location?.upazilla_id).then((response) => {
			if (response && response.success) {
				setAreas(response.response.data)
			}
			else {
				// console.log('errors: ', response);
			}
		})
	}, [location.upazilla_id, employerData.upazilla_id])

	const handleChangeSelect = (name) => (event) => {
		if (name === 'district_id') {
			setLocation({
				...location,
				district_id: event.value,
				district_name: event.label,
				upazilla_id: null,
				upazilla_name: null,
				area_id: null,
				area_name: null
			});
			setLocationDropdownSelected({
				...locationDropdownSelected,
				district: true,
				upazilla: false,
				area: false
			});
		} else if (name === 'upazilla_id') {
			setLocation({
				...location,
				upazilla_id: event.value,
				upazilla_name: event.label,
				area_id: null,
				area_name: null
			});
			setLocationDropdownSelected({
				...locationDropdownSelected,
				district: false,
				upazilla: true,
				area: false
			});
		} else {
			setLocation({
				...location,
				area_id: event.value,
				area_name: event.label
			});
			setLocationDropdownSelected({
				...locationDropdownSelected,
				district: false,
				upazilla: false,
				area: true
			});
		}
	};

	const handleChangeMultipleSelect = (name) => (event) => {
		setEmpSpecialization(event.map(val => val.value).toString())
	}

	//Using useRef to prevent unnecessary rendering
	const companyName = useRef('')
	const websiteRef = useRef('')
	const headlineRef = useRef('')
	const foundDateRef = useRef('')
	const totalOfficeRef = useRef('')
	const numberOfEmployerRef = useRef('')
	const newOpeningRef = useRef('')
	const medicalAllowanceRef = useRef('')
	const subOfficeRef = useRef('')
	const phoneRef = useRef('')
	const addressRef = useRef('')
	const videoLinkRef = useRef('')
	const natureOfBusiness = useRef('')
	const companyTypeRef = useRef('')


	// For Updating employer Data
	const [updateInfo, setUpdateInfo] = useState('')

	const updateEmployerData = () => {
		setOpenUpdateModal(false)
		setOpenDeleteModal(false)

		const phoneNumber = phoneRef.current?.value;
		if (phoneNumber) {
			if (!validatePhoneNumber(phoneNumber)) {
				alertContext.setOpenErrorAlert(true);
				alertContext.setResponseMessage(["Please enter a valid phone number!"]);
				return;
			}
		}

		const updateData = {
			employer_name: companyName.current?.value,
			web: websiteRef.current?.value,
			headline: headlineRef.current?.value,
			specialization: empSpecialization?.toString(),
			intro: intro,
			established: foundDateRef.current?.value,
			total_offices: totalOfficeRef.current?.value,
			number_of_employee: numberOfEmployerRef.current?.value,
			medical_allowance: medicalAllowanceRef.current?.value,
			new_openings: newOpeningRef.current?.value,
			sub_offices: subOfficeRef.current?.value,
			benefit: checkedBenefitList.toString(),
			contact: phoneNumber,
			map_location: addressRef.current?.value,
			video: videoLinkRef.current?.value,
			social: {
				create: socialData,
				update: socialUpdateInfo,
				delete: {
					id: deleteSocailId
				}
			},
			type_of_business: natureOfBusiness.current?.value,
			departments: departmentList.toString(),
			industries: industryList.toString(),
			company_type: companyTypeRef?.current.value,
			district_id: location.district_id,
			upazilla_id: location.upazilla_id,
			area_id: location.area_id
		}

		updateEmployerByID(employerData?.id, updateData).then((response) => {
			if (response && response.success) {
				setResponseMessage(response.response.message)
				setOpenAlert(true)
				settingEmployerData()
			}
			else {
				setErrorMessage(response?.errors[0].message[0]);
				setOpenErrorAlert(true);
			}
		}).then(() => setLoading(false), setSocialData(''), setSocialUpdateInfo(''), setDeleteSocialId(''))
	}

	useEffect(() => {
		setUpdateInfo({
			...updateInfo, social: {
				create: socialData
			}
		})
	}, [socialData])

	const handleClose = () => {
		setOpenDeleteModal(false)
		setDeleteSocialId('')
		setDeleteSocialIndex('')
	}

	const handleCloseUpdateModal = () => {
		setSocialUpdateInfo('')
		setOpenUpdateModal(false)
	}

	const memberNameRef = useRef('')
	const memberDesignationRef = useRef('')
	const memberPhotoRef = useRef('')
	const galleryRef = useRef('')
	const captionRef = useRef('')
	const contentImageRef = useRef('')
	const contentTitleRef = useRef('')
	const contentDescriptionRef = useRef('')
	const contentImageAlignRef = useRef('')

	const handleTeamInsertion = () => {
		setTeamMemberLoading(true)
		const formData = new FormData()

		formData.append('name', memberNameRef.current.value)
		formData.append('designation', memberDesignationRef.current.value)
		formData.append('leader_photo', memberPhotoRef.current.files[0])

		insertEmployerTeam(formData)
			.then(response => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					setOpenAlert(true)
					settingEmployerData()
				}
			}).finally(() => setTeamMemberLoading(false))
	}

	const handleGalleryInsertion = () => {
		setGalleryLoading(true)
		const formData = new FormData()
		formData.append('title', captionRef.current.value)
		formData.append('gallery_photo', galleryRef.current.files[0])

		insertEmployerGallery(formData)
			.then(response => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					setOpenAlert(true)
					settingEmployerData()
					captionRef.current.value = ''
					galleryRef.current.value = ''
				} else {
					setErrorMessage(response.errors[0].message)
					setOpenErrorAlert(true)
				}
			}).finally(() => setGalleryLoading(false))
	}

	const [contentModalOpen, setContentModalOpen] = useState(false)
	const [contentEditInfo, setContentEditInfo] = useState({})

	// const handleContentInsertion = () => {
	// 	setContentLoading(true)
	// 	const formData = new FormData()
	// 	formData.append('content_photo', contentImageRef.current.files[0])
	// 	formData.append('employer_content_section_id', contentTitleRef.current.value)
	// 	// formData.append('employer_content_section_id', content_section_id)
	// 	formData.append('description', contentDescriptionRef.current.value)
	// 	formData.append('align', contentImageAlignRef.current.value)

	// 	insertEmployerContent(formData)
	// 		.then(response => {
	// 			if (response && response.success) {
	// 				setResponseMessage(response.response.message)
	// 				setOpenAlert(true)
	// 				settingEmployerData()
	// 			}
	// 		}).finally(() => setContentLoading(false))
	// }

	const enableEditModal = (item) => {
		setMemberEditInfo(item)
		setOpenMemberEditModal(true)
	}

	const closeMemberEditModal = () => {
		setMemberEditInfo({})
		setOpenMemberEditModal(false)
	}

	const closeContentEditModal = () => {
		setContentModalOpen(false)
		setContentEditInfo({})
	}

	const [galleryButtonLoading, setGalleryButtonLoading] = useState(false)
	const deleteGalleryImage = (gallery_id) => {
		setGalleryButtonLoading(true)
		deleteEmployerGallery(gallery_id)
			.then((response) => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					setOpenAlert(true)
					getGalleryImages()
				}
			}).finally(() => setGalleryButtonLoading(false))
	}

	const [deleteLoading, setDeleteLoading] = useState({});

	const deleteTeamMember = (id) => {
		setDeleteLoading((prevState) => ({ ...prevState, [id]: true }));
		deleteTeamMembers(id)
			.then(response => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					// props.setOpenAlert(true)
					// props.closeMemberEditModal()
					settingEmployerData()
				}
			}).finally(() => setDeleteLoading(prevState => ({ ...prevState, [id]: false })))
	}

	// Get the current date in YYYY-MM-DD format
	const currentDate = new Date().toISOString().split('T')[0];

	return (
		<div className='atb-card-dashboard text-left'>
			<hr className='d-lg-none mt-5' />
			<h3 className='m-3'>Update Company Profile</h3> <hr />
			{
				employerDataLoading ?
					<GlobalLoader height="60vh" />
					:
					<form className='m-3' id='employer-profile-update' onSubmit={(event) => [event.preventDefault(), updateEmployerData()]}>
						<div className="row m-b30">
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Company Name</label>
									<input type="text" className="form-control atb-input-box" ref={companyName} defaultValue={employerData.employer_name} placeholder="Enter Company Name" required />
									{errors.employer_name && <Alert severity="error">{errors.employer_name}</Alert>}
								</div>
							</div>
							{/* </div> */}
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Website</label>
									<input type="url" defaultValue={employerData.web} ref={websiteRef} className="form-control atb-input-box" placeholder="http://example.com" />
									{errors.web && <Alert severity="error">{errors.web}</Alert>}
								</div>
							</div>
							<div className="col-lg-12 col-md-12">
								<div className="form-group">
									<label>Headline</label>
									<input type="text" defaultValue={employerData.headline} ref={headlineRef} className="form-control atb-input-box" placeholder="Headline of your Company" />
									{errors.headline && <Alert severity="error">{errors.headline}</Alert>}
								</div>
							</div>
							{/* </div> */}

							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Founded Date </label>
									<input type="date" className="form-control atb-input-box" ref={foundDateRef} defaultValue={employerData.established} placeholder="17/12/2018" max={currentDate} />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Category</label>
									<Select
										defaultValue={employerData.specialization?.map(val => {
											return (
												{ label: val.name, value: val.id }
											)
										}
										)}
										options={
											specializations ? specializations.map((item, index) => {
												return (
													{ label: item.name, value: item.id }
												)
											}) : " "
										}
										isMulti
										className='atb-input-box-container'
										classNamePrefix='atb-input-box'
										onChange={handleChangeMultipleSelect('specialization')}
										required
									/>
								</div>
							</div>
							<div className="col-lg-12 col-md-12">
								<div className="form-group">
									<label>Description:</label>
									{/* Text Editor advanced */}
									<JoditReact
										onChange={(content) => setIntro(content)}
										defaultValue={employerData.intro}
									/>
								</div>
								{errors.intro && <Alert severity="error">{errors.intro}</Alert>}
							</div>
						</div>

						<hr className='d-lg-none' />
						<div className="job-bx-title clearfix">
							<h5 className="font-weight-700 pull-left text-uppercase">Additional Information</h5>
						</div>

						<div className="row m-b30">
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Total Office</label>
									<input type="number" ref={totalOfficeRef} defaultValue={employerData.total_offices} className="form-control atb-input-box" placeholder="Total Number of Offices" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Number of Employees</label>
									<input type="number" ref={numberOfEmployerRef} className="form-control atb-input-box" defaultValue={employerData.number_of_employee} placeholder="Total Number of Employees" />
								</div>
							</div>
						</div>

						<div className="row m-b30">
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>New Opening</label>
									<input type="date" ref={newOpeningRef} className="form-control atb-input-box" defaultValue={employerData.new_openings} placeholder="2" max={currentDate} />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Medical Allowance</label>
									<Form.Control as="select" ref={medicalAllowanceRef} defaultValue={employerData.medical_allowance ? employerData.medical_allowance : ""} custom className="atb-input-box">
										<option value=''>Select</option>
										<option value="Yes">Yes</option>
										<option value="No">No</option>
									</Form.Control>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Sub Offices</label>
									<Form.Control as="select" ref={subOfficeRef} defaultValue={employerData.sub_offices ? employerData.sub_offices : ""} custom className="atb-input-box">
										<option value=''>Select</option>
										<option value="Yes">Yes</option>
										<option value="No">No</option>
									</Form.Control>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Business Type</label>
									<input type="text" ref={companyTypeRef} className="form-control atb-input-box" defaultValue={employerData.company_type} placeholder="Business Type" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Nature of Business</label>
									<Form.Control as="select" ref={natureOfBusiness} defaultValue={employerData.type_of_business ? employerData.type_of_business : ""} custom className="atb-input-box">
										<option value=''>Select</option>
										<option value="b2b">B2B</option>
										<option value="b2c">B2C</option>
									</Form.Control>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Department</label>
									<Select
										placeholder='Select Departments'
										options={departments?.map((item, index) => {
											return (
												{ label: item.name, value: item.id }
											)
										})
										}
										defaultValue={employerData?.employer_departments?.map((item) => {
											return (
												{ label: item.department?.name, value: item.department?.id }
											)
										})}
										onChange={(event) => setDepartmentList(event.map(val => val.value))}
										className='atb-input-box-container'
										classNamePrefix='atb-input-box'
										isMulti
									/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Industry</label>
									<Select
										placeholder='Select Industry'
										options={industries?.map((item, index) => {
											return (
												{ label: item.name, value: item.id }
											)
										})
										}
										defaultValue={employerData?.employer_industries?.map((item) => {
											return (
												{ label: item.industry?.name, value: item.industry?.id }
											)
										})}
										onChange={(event) => setIndustryList(event.map(val => val.value))}
										className='atb-input-box-container'
										classNamePrefix='atb-input-box'
										isMulti
									/>
								</div>
							</div>
						</div>

						<hr className='d-lg-none' />
						<div className="job-bx-title clearfix">
							<h5 className="font-weight-700 pull-left text-uppercase">Benefits</h5>
						</div>
						<div className='form-group'>
							<div className='row' >
								<div className='col-lg-4 col-md-4' style={{ borderRight: '1px solid grey' }}>
									<div className="bg-white">
										<ul className="list-unstyled">
											{
												empBenefitCats?.map((item, index) => {
													return (
														<div key={index}>
															<div className="collapse show" id={`${index}-collapse`}>
																<ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
																	<li className='m-2'>
																		<Link style={currentBenefit == item.id ? { marginLeft: '20px', color: 'black', borderBottom: '1px solid blue' } : { marginLeft: '5px' }} to="#" onClick={() => setCurrentBenefit(item.id)} className="link-dark text-dark">{item.title}</Link>
																	</li>
																</ul>
															</div>
														</div>
													)
												})
											}
										</ul>
									</div>
								</div>
								<div className='col-lg-8 col-md-8' >
									<div className="bg-white">
										<ul className="list-unstyled">
											{
												empBenefitCatOptns?.map((item, index) => {
													return (
														<div className="custom-control custom-checkbox" key={index}>
															<input onChange={(e) => {
																// add to list
																if (e.target.checked) {
																	setCheckedBenefitList([...checkedBenefitList, item.id]);
																}
																else {
																	// remove from list
																	setCheckedBenefitList(
																		checkedBenefitList.filter((s) => s !== item.id),
																	);
																}
															}}
																checked={defaultSelectedCheckBox(item)}
																value={item.id} type="checkbox" className="custom-control-input" id={`benefit-check${index}`} name="job_benefit" />
															<label className="custom-control-label" htmlFor={`benefit-check${index}`}>{item.title}</label>
														</div>
													)
												})
											}
										</ul>
									</div>
								</div>
							</div>
						</div>

						<hr className='d-lg-none mt-3' />
						<div className="job-bx-title clearfix">
							<h5 className="font-weight-700 pull-left text-uppercase">Contact Information</h5>
						</div>
						<div className="row m-b30">
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Phone</label>
									<InputGroup className="mb-3">
										<InputGroup.Text className=''
											style={{
												boxShadow: "var(--common-box-shadow)",
												color: "#455F87",
												fontSize: "14px",
												marginLeft: "0px",
												padding: "0 px"
											}}
											id="user-phone">+880</InputGroup.Text>
										<Form.Control
											type='number'
											aria-label="phone-number"
											aria-describedby="user-phone"
											ref={phoneRef}
											defaultValue={employerData?.contact?.substring(4)}
											className="form-control atb-input-box" placeholder="Contact Number"
											onKeyDown={(e) => handleKeyDown(e)}
										/>
									</InputGroup>
									{errors.contact && <Alert severity="error">{errors.contact}</Alert>}
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Email</label>
									<input type="email" style={{ cursor: 'not-allowed' }} disabled defaultValue={employerData.user?.email} className="form-control atb-input-box" placeholder="example@domain.com" />
								</div>
							</div>
							<div className="col-lg-12 col-md-12">
								<div className="form-group">
									<label>Full Address</label>
									<textarea type="text" ref={addressRef} defaultValue={employerData.map_location} className="form-control atb-input-box" placeholder="Address" />
									{errors.map_location && <Alert severity="error">{errors.map_location}</Alert>}
								</div>
							</div>
							<div className="col-lg-4 col-md-12">
								<div className="form-group">
									<label>Location</label>
									<Select
										options=
										{district.map(ind => {
											return (
												{ label: ind.name, value: ind.id }
											)
										})
										}
										value={location?.district_id ? { label: location.district_name, value: location.district_id } : null}
										className='atb-input-box-container'
										classNamePrefix='atb-input-box'
										onChange={handleChangeSelect('district_id')}
									/>
								</div>
							</div>
							<div className="col-lg-4 col-md-12">
								<div className="form-group">
									<label>Upazilla</label>
									<Select
										options=
										{upazillas.map(ind => {
											return (
												{ label: ind.name, value: ind.id }
											)
										})
										}
										value={location?.upazilla_id ? { label: location.upazilla_name, value: location.upazilla_id } : null}
										className='atb-input-box-container'
										classNamePrefix='atb-input-box'
										onChange={handleChangeSelect('upazilla_id')}
									/>
								</div>
							</div>
							<div className="col-lg-4 col-md-12">
								<div className="form-group">
									<label>Area</label>
									<Select
										options=
										{areas.map(ind => {
											return (
												{ label: ind.name, value: ind.id }
											)
										})
										}
										value={location?.area_id ? { label: location?.area_name, value: location?.area_id } : null}
										className='atb-input-box-container'
										classNamePrefix='atb-input-box'
										onChange={handleChangeSelect('area_id')}
									/>
								</div>
							</div>
							<div className="col-lg-12 col-md-12">
								<div className="form-group">
									<label>Video (Youtube Link)</label>
									<input type="url" ref={videoLinkRef} className="form-control atb-input-box" defaultValue={employerData.video} placeholder="https://youtube.com/" />
								</div>
							</div>
						</div>

						<hr className='d-lg-none' />
						<div className="job-bx-title clearfix">
							<h5 className="font-weight-700 pull-left text-uppercase">Current Links</h5>
						</div>
						<table>
							<thead>
								<tr style={{ borderBottom: '1px solid rgba(0,0,0,.3)' }}>
									<th className='table-head' scope="col">Platform</th>
									<th className='table-head' scope="col">Link</th>
									<th className='table-head' scope="col">Edit</th>
									<th className='table-head' scope="col">Delete</th>
								</tr>
							</thead>
							<tbody>
								{
									employerData.social_networks?.map((item, index) => {
										return (
											<tr key={item.pivot?.id} style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
												<td className='table-text'>
													{item.name}
												</td>
												<td className='table-text'>
													<p>{item.pivot?.link} </p>
												</td>
												<td className='table-text'><img onClick={() => [setSocialUpdateInfo({ ...socialUpdateInfo, id: item.id, social_network_id: item.pivot?.id, name: item.name, link: item.pivot?.link }), setOpenUpdateModal(true)]} src='/v2/icons/write.png' /></td>
												<td className='table-text'><img onClick={() => [setDeleteSocialId(item?.pivot.id), setDeleteSocialIndex(index), setOpenDeleteModal(true)]} src='/v2/icons/cross.png' /></td>
											</tr>
										)
									})
								}
							</tbody>
						</table>

						<hr className='d-lg-none' />
						<div className="job-bx-title clearfix">
							<h5 className="font-weight-700 pull-left text-uppercase">Add Social Networks</h5>
						</div>
						<div className='row m-b30'>

							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>Platform</label>
									<Select
										placeholder='Select Platform'
										options={social?.map((item, index) => {
											return (
												{ label: item.name, value: item.id }
											)
										})
										}
										onChange={(event) => setSocialData({ ...socialData, social_network_id: event.value })}
										className='atb-input-box-container'
										classNamePrefix='atb-input-box'
									/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="form-group">
									<label>URL</label>
									<input type='text' className='form-control atb-input-box link-box'
										onChange={(event) => setSocialData({ ...socialData, link: event.target.value })}
										placeholder='Insert Link'
									/>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col'>
								<LoadingButton loading={loading} variant="contained" type="submit" className="employer-update-button m-b30 d-none d-lg-block">Save Changes</LoadingButton>
								<LoadingButton loading={loading} variant="contained" type="submit" className="employer-update-button btn-block m-b30 d-lg-none">Save Changes</LoadingButton>
								{/* <button type="button" className="discard-button m-b30" onClick={(e) => [setUpdateInfo('')]}>Discard</button> */}

							</div>
						</div>
					</form>
			}

			<Box mt={2} />
			<Divider />
			<Box mt={8} />
			<div className='m-3'>
				<hr className='d-lg-none' />
				<Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
					<Box>
						<Typography
							variant="h5"
							sx={{
								fontWeight: 400,
								fontSize: '20px',
								textTransform: 'uppercase'
							}}>
							Meet The Team
						</Typography>
					</Box>
					<Button variant='outlined' onClick={() => enableEditModal()}>Add New Member</Button>
				</Stack>
				<Box mt={2} />
				<Divider />
				<Box mt={2} />
				<div className='row m-b30'>
					{
						existingTeamMembers?.map((item, index) => {
							return (
								<div className='col-md-6 col-lg-6  m-b30' key={index}>
									<Grid item lg={6} md={6} sm={12} xs={12}>
										<Box
											sx={{
												height: '100%',
												display: 'flex',
												flexDirection: { xs: 'column', sm: 'column', lg: 'row' },
												alignItems: 'center',
												marginBottom: '10px'
											}}
										>
											<Box
												sx={{
													border: '4px solid #e0e0e0',
													borderRadius: '50%',
													padding: 1,
													flexShrink: 0,
													marginRight: { md: 2, xs: 0 },
													marginBottom: { xs: 4, md: 4 },
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<Avatar
													alt="User Profile Picture"
													src={item.s3_url}
													sx={{
														height: "120px",
														width: "120px",
													}}
												/>
											</Box>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'space-between',
													height: '100%',
													width: '100%',
													flexGrow: 1,
												}}
											>
												<Stack
													spacing={1}
													sx={{
														overflowY: 'auto',
														height: 'auto',
														flexGrow: 1,
														'@media (min-width: 768px)': {
															height: '180px',
														},
													}}
												>
													<Typography
														variant="body2"
														sx={{
															fontWeight: 700,
															fontSize: '18px',
														}}
													>
														{item.name}
													</Typography>
													<Typography
														variant="body2"
														sx={{
															fontWeight: 700,
															fontSize: '16px',
															color: '#0275D8',
														}}
													>
														{item.designation}
													</Typography>
													<Typography
														variant="body2"
														sx={{
															fontWeight: 700,
															fontSize: '14px',
															color: '#72737C',
														}}
													>
														{item.description}
													</Typography>
												</Stack>
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'flex-start',
														mt: 2,
													}}
												>
													<Button
														onClick={() => enableEditModal(item)}
														variant="contained"
														sx={{ margin: '2px 8px 2px 0px' }}
													>
														Edit
													</Button>
													<LoadingButton
														onClick={() => deleteTeamMember(item.id)}
														loading={deleteLoading[item.id] || false}
														variant="outlined"
														sx={{ margin: '2px' }}
													>
														Delete
													</LoadingButton>
												</Box>
											</Box>
										</Box>
									</Grid>
									<Divider
										sx={{
											display: {
												xs: 'block',
												sm: 'block',
												md: 'none',
											},
											mt: 2,
										}}
									/>
								</div>
							)
						})
					}
				</div>
			</div>


			<div className='m-3'>
				<hr className='d-lg-none' />
				<Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
					<Box>
						<Typography
							variant="h5"
							sx={{
								fontWeight: 400,
								fontSize: '20px',
								textTransform: 'uppercase'
							}}>
							Profile Content
						</Typography>
					</Box>
					<Button variant='outlined' onClick={() => [setContentEditInfo(null), setContentModalOpen(true)]}>Add New Content</Button>
				</Stack>
				<Box mt={2} />
				<Divider />
				<Box mt={2} />


				<hr className='d-lg-none' />

				{
					existingContent?.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<div className='m-3'>
									{
										<Box mt={5}>
											<Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
												<Box>
													<Typography
														variant="h5"
														sx={{
															fontWeight: 400,
															fontSize: '20px',
															textTransform: 'uppercase'
														}}>
														{item.employer_content_sections?.title}
													</Typography>
												</Box>
												<Button variant='outlined' onClick={() => [setContentEditInfo(item), setContentModalOpen(true)]}>Edit Section </Button>
											</Stack>
											<Box mt={2} />
											<Divider />
											<Box mt={2} />

											<Grid container spacing={2}>
												<Grid item md={6} xs={12}>
													<img src={item.s3_url} width="100%" height="100%" alt="" />
												</Grid>
												<Grid item md={6} xs={12}>
													<Stack spacing={4} sx={{ padding: { xs: 1, md: 5 } }}>
														<Box>
															<Typography
																variant="h4"
																sx={{
																	fontWeight: 700,
																	fontSize: { xs: '18px', md: '24px' },
																}}>
																{item.employer_content_sections?.title}
															</Typography>
															<Typography
																variant="body1"
																sx={{
																	fontWeight: 700,
																	fontSize: '14px',
																	color: '#0275D8'
																}}>
																(Scope of work)
															</Typography>
														</Box>
														<Box sx={{ display: { xs: 'block', md: 'none' } }}>
															<Divider />
														</Box>
														<Typography
															variant="h4"
															sx={{
																fontWeight: 700,
																fontSize: '16px',
																whiteSpace: "pre-line",
																textAlign: "justify"
															}}>
															{item.description}
														</Typography>
														<Box sx={{ display: { xs: 'block', md: 'none' } }}>
															<Divider />
														</Box>
													</Stack>
												</Grid>
											</Grid>
										</Box>
									}
								</div>

							</React.Fragment>
						)
					})
				}
			</div>

			<div className="job-bx-title clearfix">
				<h5 className="font-weight-700 pull-left text-uppercase">Image Gallery</h5>
			</div>
			<div className='row'>
				{
					existingGallery?.map((item, index) => {
						return (
							<div className='col-lg-4 col-md-4'>
								<Card sx={{ width: '100%', margin: 2 }} key={index}>
									<CardMedia
										sx={{ height: 140 }}
										image={item.s3_url}
										title={item.title}
									/>
									<CardContent>
										<Typography gutterBottom variant="p" component="div">
											{item.title}
										</Typography>
									</CardContent>
									<CardActions>
										<LoadingButton loading={galleryButtonLoading} size="small" variant='contained' onClick={() => deleteGalleryImage(item.id)}>Delete</LoadingButton>
									</CardActions>
								</Card>
							</div>
						)
					})
				}
			</div>

			<div className='form-group m-t30'>
				<div className="row m-b30">
					<div className="col-lg-6 col-md-6">
						<div className="form-group">
							<label>Caption</label>
							<input type="text" ref={captionRef} className="form-control atb-input-box" placeholder="Enter Image Caption" />
						</div>
					</div>
					<div className="col-lg-6 col-md-6">
						<div className="form-group">
							<label>Upload Image</label>
							<input type="file" ref={galleryRef} className="form-control atb-input-box" placeholder="Upload Image" />
							<small style={{ fontSize: '10px', color: 'green' }}> Please upload an image with a maximum size of 390 x 250 px.</small>
						</div>
					</div>
					<div className="col-lg-12 col-md-12">
						<LoadingButton onClick={() => handleGalleryInsertion()} loading={galleryLoading} variant="contained" type="submit" className="employer-update-button m-b30 d-none d-lg-block">Save Changes</LoadingButton>
					</div>
				</div>
			</div>

			<UpdateOrganizationStructure
				employerId={employerData.id}
				image={employerData.organizational_structure_image}
				imageS3={employerData.organizational_structure_image_s3}
				renderState={settingEmployerData}
			/>

			<UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={updateEmployerData} />
			<UpdateSocialModal open={openUpdateModal} onClose={handleCloseUpdateModal} updateItem={updateEmployerData} socialUpdateInfo={socialUpdateInfo} setSocialUpdateInfo={setSocialUpdateInfo} />

			<TeamMemberEdit setResponseMessage={setResponseMessage} setOpenAlert={setOpenAlert} open={openMemberEditModal} item={memberEditInfo} closeMemberEditModal={closeMemberEditModal} setOpenErrorAlert={setOpenErrorAlert} setErrorMessage={setErrorMessage} />

			<ContentUpdateModal setResponseMessage={setResponseMessage} setOpenAlert={setOpenAlert} open={contentModalOpen} item={contentEditInfo} closeContentEditModal={closeContentEditModal} contentTitleList={contentTitleList} setOpenErrorAlert={setOpenErrorAlert} setErrorMessage={setErrorMessage} />
			<SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
			<ErrorAlert open={openErrorAlert} message={errorMessage} onHide={() => setOpenErrorAlert(false)} />
		</div>
	)
}

export default UpdateEmployer