import React, { useEffect, useState, useRef} from 'react';
import { TextField, Grid, InputLabel, Checkbox, Typography, Divider, Skeleton, Box, Button, Stack, FormControlLabel, FormGroup } from '@mui/material';
import { candidateRegisterFlow, stepperStepInfo } from '../../../../fetchAPI';
import { LoadingButton } from '@mui/lab';
import RequiredAsterisk from '../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk';
import { fetchIpAddress } from '../../../helpers/helperFunctions';

const getDobMaxYear = () => {
    let d = new Date();
    d.setFullYear(d.getFullYear());
    return d.toJSON().slice(0, 10);
  };

export default function ExperienceInformation({ handleNext, handleBack, job_id, candidateData, candLoader, settingCandidateData }) {
    const organizationNameRef = useRef('')
    const roleRef = useRef('')
    const startDateRef = useRef('')
    const endDateRef = useRef('')
    const descriptionRef = useRef('')
    const [currentlyWorking, setCurrentlyWorking] = useState(false)
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [latestExpInfo, setLatestExpInfo] = useState({})
    const [isFresher, setIsFresher] = useState(candidateData?.is_fresher == 1 ? true : false)
    const [saveButtonHoverEffect, setSaveButtonHoverEffect] = useState("outlined");

    useEffect(() => {
        if (candidateData.candidate_experience) {
            setLatestExpInfo(candidateData?.candidate_experience?.length != 0 ? candidateData.candidate_experience[0] : {})
        }
    }, [candidateData])

    useEffect(() => {
        setCurrentlyWorking(latestExpInfo?.currently_working)
    }, [latestExpInfo])

    const handleSUbmit = () => {
        setLoading(true)
        const paramData = {
            organization_name: organizationNameRef.current?.value,
            role: roleRef.current?.value,
            start_date: startDateRef.current?.value,
            end_date: endDateRef.current?.value,
            currently_working: currentlyWorking,
            organization_desc: descriptionRef.current?.value,
            is_fresher: 0
        }
        candidateRegisterFlow('work_experience', job_id, paramData)
            .then((response) => {
                if (response && response.success) {
                    handleNext()
                } else {
                    setResponseMessage(response.errors[0]?.message)
                }
            }).finally(() => setLoading(false), setResponseMessage(''))
        
        const ip = fetchIpAddress();
        const platform = "web";
        const stepNo = 3;

        stepperStepInfo(stepNo, ip, platform).then((response) => {
        if(response && response.success) {
            setLoading(false);
            }
        })

    }

    const handleFresher = () =>{
        setLoading(true)
        const paramData = {
            is_fresher: 1
        }
        candidateRegisterFlow('work_experience', job_id, paramData)
        .then((response) => {
            if (response && response.success) {
                handleNext()
            } else {
                setResponseMessage(response.errors[0]?.message)
            }
        }).finally(() => setLoading(false), setResponseMessage(''))
    }

    // date field placeholder color change
    const [expStart, setExpStart] = useState(latestExpInfo.start_date || '');
    const [expEnd, setExpEnd] = useState(latestExpInfo.end_date || '');
  
    const dateHandleChange = (setter) => (event) => {
      setter(event.target.value);
    };
  
    const isPlaceholderFormat = (value) => {
      return value === '' || value === 'mm-dd-yyyy';
    };

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Typography align='left' variant='h6' sx={{ fontWeight: 600 }}>Work Experience</Typography>
            </Box>
            <Divider />
            {
                candLoader ?
                    <div className='mt-3 col-lg-12 col-md-12'>
                        <Skeleton variant="text" height={60} />
                        <Skeleton variant="text" height={50} />
                        <div className='text-left'>
                            <Skeleton variant="text" />
                        </div>
                        <div className='mt-3 text-left'>
                            <Skeleton variant="rounded" height={120} />
                        </div>
                        <div className='text-left'>
                            <Skeleton variant="text" width={80} height={50} />
                        </div>
                    </div>
                    :
                    <form
                        className="ga_stepper_candidate_register_work_qualification_form" // do not remove ga_ class
                        onSubmit={(e) => [e.preventDefault(), handleSUbmit()]}
                    >
                        {responseMessage ?
                            <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                {
                                    responseMessage?.map((item, index) => {
                                        return (
                                            <center key={index}>
                                                <small style={{ color: 'red' }}> {item} </small>
                                            </center>
                                        )
                                    })
                                }
                            </div>
                            : ''}
                        <Stack direction='row' xs={12}>
                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{paddingLeft: "4px !important"}}>
                                    <FormControlLabel control={<Checkbox onChange={(e) => setIsFresher(e.target.checked)} defaultChecked={isFresher} />} label="No Work Experience" />
                                </Grid>
                            </Grid>
                        </Stack>
                        {
                            isFresher ?
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", pt: 2 }}>
                                    <Button
                                        variant='outlined'
                                        // disabled
                                        sx={{ mr: 1, minWidth: {sm: "102px"} }}
                                        onClick={handleBack}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ display: "flex" }}>
                                      <LoadingButton
                                        variant="contained"
                                        loading={loading}
                                        onClick={handleFresher}
                                        sx={{minWidth: {sm: "102px"}}}
                                      >
                                        Save
                                      </LoadingButton>
                                      <Button
                                        sx={{ ml: {xs: 1, md: 2, minWidth: {sm: "102px"}} }}
                                        variant="contained"
                                        loading={loading}
                                        onClick={handleNext}
                                      >
                                        Continue
                                      </Button>
                                    </Box>
                                </Box>
                                :
                                <>
                                    <Grid container spacing={2} mt={{xs: 0, sm: 0, lg: 1}}>
                                        <Grid item lg={6} xs={12}>
                                            <InputLabel sx={{ fontWeight: 'bold' }}>Organization Name <RequiredAsterisk /></InputLabel>
                                            <TextField
                                                required
                                                type='text'
                                                name='organization_name'
                                                variant='outlined'
                                                placeholder='Enter Your Organization Name'
                                                fullWidth
                                                inputRef={organizationNameRef}
                                                defaultValue={latestExpInfo.organization_name}
                                                inputProps={{
                                                  style: {
                                                    padding: "10px 14px",
                                                  },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={6} xs={12}>
                                            <InputLabel sx={{ fontWeight: 'bold' }}>Designation <RequiredAsterisk /></InputLabel>
                                            <TextField
                                                required
                                                type='text'
                                                name='role'
                                                variant='outlined'
                                                placeholder='Enter Your Designation'
                                                fullWidth
                                                inputRef={roleRef}
                                                defaultValue={latestExpInfo.role}
                                                inputProps={{
                                                  style: {
                                                    padding: "10px 14px",
                                                  },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={1} sx={{ alignItems: 'center' }}>
                                        <Grid item lg={4} xs={12}>
                                            <InputLabel sx={{ fontWeight: 'bold' }}>Start Date <RequiredAsterisk /></InputLabel>
                                            <TextField
                                                required
                                                type='date'
                                                name='start_date'
                                                variant='outlined'
                                                fullWidth
                                                inputRef={startDateRef}
                                                defaultValue={latestExpInfo.start_date}
                                                onChange={dateHandleChange(setExpStart)}
                                                inputProps={{
                                                  style: {
                                                    padding: "10px 14px",
                                                    color: isPlaceholderFormat(expStart) && (!latestExpInfo.start_date || latestExpInfo.start_date === '') ? '#949494' : 'black',
                                                  },
                                                  min: "1950-01-01",
                                                  max: getDobMaxYear(),
                                                }}
                                            />
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <InputLabel sx={{ fontWeight: 'bold' }}>End Date <RequiredAsterisk /></InputLabel>
                                            <TextField
                                                required={!currentlyWorking}
                                                disabled={currentlyWorking}
                                                type='date'
                                                name='end_date'
                                                variant='outlined'
                                                fullWidth
                                                inputRef={endDateRef}
                                                defaultValue={latestExpInfo.end_date}
                                                onChange={dateHandleChange(setExpEnd)}
                                                inputProps={{
                                                  style: {
                                                    padding: "10px 14px",
                                                    color: isPlaceholderFormat(expEnd) && (!latestExpInfo.end_date || latestExpInfo.end_date === '') ? '#949494' : 'black',
                                                  },
                                                  min: "1950-01-01",
                                                  max: getDobMaxYear(),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={4} xs={12} sx={{paddingLeft: "4px !important"}}>
                                            <Box>
                                               <InputLabel sx={{display: {xs: "none", sm: "none", lg:"block"}, visibility: "hidden" }}>
                                                 .
                                               </InputLabel>
                                               <FormGroup>
                                                   <FormControlLabel control={<Checkbox onChange={(e) => setCurrentlyWorking(e.target.checked)} defaultChecked={latestExpInfo.currently_working} />} label="Currently Working Here" />
                                               </FormGroup>
                                            </Box>
                                            {/* <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item lg={1} md={1} sm={1} xs={2}>
                                            <Checkbox onChange={(e)=> setCurrentlyWorking(e.target.checked)} defaultChecked={latestExpInfo.currently_working} />
                                        </Grid>
                                        <Grid item lg={11} md={11} sm={11} xs={10}>
                                            <Typography >Currently working here</Typography>
                                        </Grid>
                                    </Grid> */}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} mt={{xs: 0, sm: 0, lg: 1}}>
                                        <Grid item lg={12} xs={12}>
                                            <InputLabel sx={{ fontWeight: 'bold' }}>Job Description</InputLabel>
                                            <TextField
                                                id="exampleFormControlTextarea1"
                                                placeholder="Write Desctiption Here"
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                fullWidth
                                                inputRef={descriptionRef}
                                                defaultValue={latestExpInfo.organization_desc}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", pt: 4 }}>
                                        <Button
                                            variant='outlined'
                                            // disabled
                                            sx={{ mr: 1, minWidth: {sm: "102px"} }}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{ display: "flex" }}>
                                          <LoadingButton
                                            sx={{ ml: {xs: 1, md: 2, minWidth: {sm: "102px"}} }}
                                            variant="contained"
                                            loading={loading}
                                            type="submit"
                                          >
                                            Continue
                                          </LoadingButton>
                                        </Box>
                                    </Box>
                                </>
                        }
                    </form>
            }
        </>
    )
}