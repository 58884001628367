import React, { createContext, useEffect, useRef, useState } from "react"
import { getBenifits, getBenifitChild, getEmployerBenefits, getEmployerBenefitOptions, getEmployerBenefitList, getJobBenefitList } from "../../fetchAPI";
import { useJobSearchHook } from "../CustomHooks/jobSearchHook";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

export const JobSearchContext = createContext();

const JobSearchContextProvider = (props) => {
    const [result, setResult] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [jobSearchData, setJobSearchData] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [employerAddress, setEmployerAddress] = useState('')
    const [salaryFrom, setSalaryFrom] = useState('')
    const [salaryTo, setSalaryTo] = useState('')
    const [jobSearchResultPagination, setJobSearchResultPagination] = useState('')
    const [searchIndustry, setSearchIndustry] = useState('')
    const [searchDepartment, setSearchDepartment] = useState('')
    const [searchPosition, setSearchPosition] = useState('')
    const [searchExperience, setSearchExperience] = useState('')
    const [searchSkill, setSearchSkill] = useState('')
    const [keyword, setKeyword] = useState('')
    const [sortBy, setSortBy] = useState('')

    const [typeCheck, setTypeCheck] = useState([]);
    const [qualiCheck, setQualiCheck] = useState('');
    const [expireSoon, setExpireSoon] = useState(false)
    const [negotiableSalary, setNegotiableSalary] = useState(false)
    const [isLiveJobs, setIsLiveJobs] = useState(false)
    const [highPaid, setHighPaid] = useState(false)
    const [currencySearch, setCurrencySearch] = useState('')

    const [searchLoad, setSearchLoad] = useState(false)

    const [defaultSelectDepartment, setDefaultSelectDepartment] = useState('')
    const [defaultCurrencySearch, setDefaultCurrencySearch] = useState('')
    const [defaultSelectIndustry, setDefaultSelectIndustry] = useState('')
    const [defaultSelectPosition, setDefaultSelectPosition] = useState('')
    const [defaultSearchExperience, setDefaultSearchExperience] = useState('')
    const [defaultSearchSkills, setDefaultSearchSkills] = useState('')

    const [minimumExperience, setMinimumExperience] = useState('')
    const [maximumExperience, setMaximumExperience] = useState('')

    const [ageFrom, setAgeFrom] = useState('')
    const [ageTo, setAgeTo] = useState('')

    const [searchGender, setSearchGender] = useState('')

    const [employerName, setEmployeName] = useState('')
    const [levelCheck, setlevelCheck] = useState('');

    const [currentPage, setCurrentPage] = useState(1)

    //Benefit api calls

    const [jobbenefitCat, setjobBenefitCat] = useState([])
    const [jobbenefitOptions, setjobBenefitOptions] = useState([])
    const [currentJobBenefit, setCurrentJobBenefit] = useState(2)
    const [checkedJobBenefitList, setCheckedJobBenefitList] = useState([])
    const [jobBenefitLoader, setJobBenefitLoader] = useState(false)
    const [activeJobBenefitTitle, setActiveJobBenefitTitle] = useState('Working Hours and Leave')

    const [empbenefitCat, setempBenefitCat] = useState([])
    const [empbenefitOptions, setempBenefitOptions] = useState([])
    const [currenEmpbBenefit, setCurrentEmpBenefit] = useState(1)
    const [checkedEmpBenefitList, setCheckedEmpBenefitList] = useState([])
    const [empBenefitLoader, setEmpBenefitLoader] = useState(false)
    const [activEmpbBenefitTitle, setActiveEmpBenefitTitle] = useState('MNC, International, Forbes')

    const [isCxo, setIsCxo] = useState(false)

    const { jobs, isLoading, status } = useJobSearchHook(jobSearchData, pageNumber)
    const { data: benefitResult } = useQuery('benefits', getBenifits, { staleTime: Infinity })
    const { data: employerBenefitresults } = useQuery('Employer Benefits', getEmployerBenefits, { staleTime: Infinity })
    const { data: employerBenefitListResults } = useQuery('Employer Benefits List', getEmployerBenefitList, { staleTime: Infinity })
    const { data: jobBenefitListResults } = useQuery('Job Benefits List', getJobBenefitList, { staleTime: Infinity })
    const { data: jobBenefitChild } = useQuery(['job Benefit Child', currentJobBenefit], getBenifitChild, { staleTime: Infinity })


    // useEffect(() => {
    // getBenifits()
    //     .then(response => {
    //         if (response && response.success) {
    //             setjobBenefitCat(response.response.data)
    //         }
    //     })
    //     .catch(error => {
    //         console.log('Get benefit error', error)
    //     })

    // getEmployerBenefits()
    //     .then(response => {
    //         if (response && response.success) {
    //             setempBenefitCat(response.response.data)
    //         }
    //     })
    //     .catch(error => {
    //         console.log('Get Employer benefit error', error)
    //     })
    // }, [])

    useEffect(() => {
        setJobBenefitLoader(true)
        getBenifitChild(currentJobBenefit)
            .then(response => {
                if (response && response.success) {
                    setjobBenefitOptions(response.response.data?.child)
                }
            })
            .catch(error => {
                console.log('Get benefit child error', error)
            })
            .finally(() => setJobBenefitLoader(false))

    }, [currentJobBenefit])

    useEffect(() => {
        setEmpBenefitLoader(true)
        getEmployerBenefitOptions(currenEmpbBenefit)
            .then(response => {
                if (response && response.success) {
                    setempBenefitOptions(response.response.data?.child)
                }
            })
            .catch(error => {
                console.log('Get benefit child error', error)
            })
            .finally(() => setEmpBenefitLoader(false))
    }, [currenEmpbBenefit])

    useEffect(() => {
        setJobSearchData({
            keyword: keyword,
            employer_name: employerName,
            title: jobTitle,
            salary_range_from: parseInt(salaryFrom),
            salary_range_to: parseInt(salaryTo),
            department: searchDepartment,
            industry: searchIndustry,
            position: searchPosition,
            experience: searchExperience,
            address: employerAddress,
            type: typeCheck?.toString(),
            level: levelCheck?.toString(),
            qualification: qualiCheck?.toString(),
            skill: searchSkill?.toString(),
            job_benefit: checkedJobBenefitList?.toString(),
            expire_soon: expireSoon,
            negotiable: negotiableSalary,
            minimum_experience: minimumExperience,
            maximum_experience: maximumExperience,
            live_job: isLiveJobs,
            high_paid_job: highPaid,
            currency: currencySearch,
            age_from: ageFrom,
            age_to: ageTo,
            gender: searchGender,
            sort_by: sortBy,
            is_cxo: isCxo
        })
    }, [keyword, jobTitle, employerAddress, employerName, typeCheck, levelCheck, qualiCheck, salaryTo, salaryFrom, searchDepartment, searchIndustry, searchPosition, searchExperience, searchSkill, expireSoon, negotiableSalary, minimumExperience, maximumExperience, isLiveJobs, highPaid, currencySearch, ageFrom, ageTo, searchGender, sortBy, isCxo])

    // const callJobSearchApi =() => {
    // 	setSearchLoad(true)

    // searchJob(jobSearchData,pageNumber).then(response=>{
    //     setResult(response.response.data)
    //     setJobSearchResultPagination(response.response.pagination)
    // }).catch(error=>{
    //     // console.log("Data fetching error : ", error)
    // }).finally(()=>{
    //     setSearchLoad(false)
    // })
    // }

    // useEffect(()=>{
    //   callJobSearchApi()
    // },[jobSearchData, pageNumber])

    const location = useLocation()
    useEffect(() => {
        if (location.pathname === '/') {
            setJobTitle('')
            setEmployerAddress('')
            setDefaultSelectDepartment('')
            setSearchDepartment('')
            setSalaryFrom('')
            setSalaryTo('')
            setExpireSoon(false)
            setNegotiableSalary(false)
            setEmployeName('')
            setSearchIndustry('')
            setSearchPosition('')
            setDefaultSelectIndustry('')
            setDefaultSelectPosition('')
            setQualiCheck('')
            setTypeCheck('')
            setlevelCheck('')
            setSearchSkill('')
            setMinimumExperience('')
            setMaximumExperience('')
            setAgeFrom('')
            setAgeTo('')
            setSearchGender('')
            setIsLiveJobs(false)
            setHighPaid(false)
            setKeyword('')
            setPageNumber(1)
        }

    }, [location.pathname])

    return (
        <JobSearchContext.Provider value={{
            employerName, setEmployeName,
            jobTitle, setJobTitle,
            salaryFrom, setSalaryFrom,
            salaryTo, setSalaryTo,
            searchDepartment, setSearchDepartment,
            searchIndustry, setSearchIndustry,
            searchPosition, setSearchPosition,
            searchExperience, setSearchExperience,
            qualiCheck, setQualiCheck,
            typeCheck, setTypeCheck,
            levelCheck, setlevelCheck,
            searchSkill, setSearchSkill,
            expireSoon, setExpireSoon,
            negotiableSalary, setNegotiableSalary,
            isLiveJobs, setIsLiveJobs,
            highPaid, setHighPaid,

            employerAddress, setEmployerAddress,
            pageNumber, setPageNumber,
            jobSearchResultPagination,

            defaultSelectDepartment, setDefaultSelectDepartment,
            defaultSelectIndustry, setDefaultSelectIndustry,
            defaultSelectPosition, setDefaultSelectPosition,
            defaultSearchExperience, setDefaultSearchExperience,
            defaultSearchSkills, setDefaultSearchSkills,

            currentPage, setCurrentPage,

            jobSearchData, setJobSearchData,
            searchLoad, setSearchLoad,
            result,

            // callJobSearchApi,

            // jobbenefitCat, setjobBenefitCat,
            jobbenefitCat: benefitResult ? benefitResult.response.data : [],
            empbenefitCat: employerBenefitresults ? employerBenefitresults.response.data : [],
            employerBenefitList: employerBenefitListResults ? employerBenefitListResults.response.data : [],
            jobBenefitList: jobBenefitListResults ? jobBenefitListResults.response.data : [],
            jobbenefitOptions: jobBenefitChild ? jobBenefitChild.response.data?.child : [],
            // jobbenefitOptions, setjobBenefitOptions,
            currentJobBenefit, setCurrentJobBenefit,
            checkedJobBenefitList, setCheckedJobBenefitList,
            jobBenefitLoader, setJobBenefitLoader,
            activeJobBenefitTitle, setActiveJobBenefitTitle,

            // empbenefitCat, setempBenefitCat,
            empbenefitOptions, setempBenefitOptions,
            currenEmpbBenefit, setCurrentEmpBenefit,
            checkedEmpBenefitList, setCheckedEmpBenefitList,
            empBenefitLoader, setEmpBenefitLoader,
            activEmpbBenefitTitle, setActiveEmpBenefitTitle,
            minimumExperience, setMinimumExperience,
            maximumExperience, setMaximumExperience,
            currencySearch, setCurrencySearch,
            defaultCurrencySearch, setDefaultCurrencySearch,

            setAgeFrom, setAgeTo, setSearchGender, keyword, setKeyword, sortBy, setSortBy, isCxo, setIsCxo,

            jobs, isLoading, status,

        }}>
            {props.children}
        </JobSearchContext.Provider>
    );

};

export default JobSearchContextProvider;