
import React, { useRef, useState, useEffect } from 'react';
import { TextField, Grid, InputLabel, Box, Divider, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {Skeleton} from '@mui/material';
import { candidateRegisterFlow, stepperStepInfo } from '../../../../fetchAPI';
import RequiredAsterisk from '../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk';
import { fetchIpAddress } from '../../../helpers/helperFunctions';

const getDobMaxYear = () => {
    let d = new Date();
    d.setFullYear(d.getFullYear());
    return d.toJSON().slice(0, 10);
  };

export default function EducationInformation({handleNext, handleBack, job_id, candidateData, candLoader, settingCandidateData}) {
    const institutenameRef = useRef('')
    const degreeNameRef = useRef('')
    const gradeRef = useRef('')
    const startDateRef = useRef('')
    const endDateRef = useRef('')
    const descriptionRef = useRef('')
    const [loading, setLoading]= useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [latestEduInfo, setLatestEduInfo] = useState({})
    const [saveButtonHoverEffect, setSaveButtonHoverEffect] = useState("outlined");

    useEffect(()=>{
        if(candidateData.candidate_education){
            setLatestEduInfo(candidateData.candidate_education?.length !== 0 ? candidateData.candidate_education[0] : {})
        }
    },[candidateData])

    const handleSUbmit =()=>{
        setLoading(true)
        const paramData = {
            degree_institute : institutenameRef.current?.value,
            degree_name : degreeNameRef.current?.value,
            degree_start : startDateRef.current?.value,
            degree_end : endDateRef.current?.value,
            degree_grade : gradeRef.current?.value,
            degree_detail : descriptionRef.current?.value
        }
        candidateRegisterFlow('academic_qualification', job_id, paramData )
        .then((response)=> {
            if(response && response.success){
                handleNext()
            }else{
                setResponseMessage(response.errors[0]?.message)
            }
        }).finally(()=> setLoading(false), setResponseMessage(''))

        const ip = fetchIpAddress();
        const platform = "web";
        const stepNo = 2;
    
        stepperStepInfo(stepNo, ip, platform).then((response) => {
          if(response && response.success) {
            setLoading(false);
          }
        })

    }

    // date field placeholder color change
    const [degreeStart, setDegreeStart] = useState(latestEduInfo.degree_start || '');
    const [degreeEnd, setDegreeEnd] = useState(latestEduInfo.degree_end || '');
  
    const dateHandleChange = (setter) => (event) => {
      setter(event.target.value);
    };
  
    const isPlaceholderFormat = (value) => {
      return value === '' || value === 'mm-dd-yyyy';
    };

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Typography align='left' variant='h6' sx={{ fontWeight: 600 }}>Academic Qualification</Typography>
            </Box>
            <Divider />
            {
                candLoader?
                <div className='mt-3 col-lg-12 col-md-12'>
                    <Skeleton variant="text" height={60} />
                    <Skeleton variant="text" height={50} />
                    <div className='text-left'>
                        <Skeleton variant="text" />
                    </div>
                    <div className='mt-3 text-left'>
                        <Skeleton variant="rounded" height={120} />
                    </div>
                    <div className='text-left'>
                        <Skeleton variant="text" width={80} height={50} />
                    </div>
                </div>
                :
            <form 
            className="ga_stepper_candidate_register_academic_qualification_form" // do not remove ga_ class
            onSubmit={(e) => [e.preventDefault(), handleSUbmit()]}
            >
                {responseMessage ?
                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                        {
                            responseMessage?.map((item,index)=>{
                                return(
                                    <center key={index}>
                                        <small style={{ color: 'red' }}> {item} </small>
                                    </center> 
                                )
                            })
                        }
                    </div>
                    : ''}
                <Grid container spacing={2} mt={3}>
                    <Grid item lg={6} xs={12}>
                        <InputLabel sx={{ fontWeight: 'bold' }}>Institute Name <RequiredAsterisk /></InputLabel>
                        <TextField
                            required
                            type='text'
                            name='degree_institute'
                            variant='outlined'
                            placeholder='Enter Your Institute Name'
                            fullWidth
                            inputRef={institutenameRef}
                            defaultValue={latestEduInfo.degree_institute}
                            inputProps={{
                              style: {
                                padding: "10px 14px",
                              },
                            }}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <InputLabel sx={{ fontWeight: 'bold' }}>Degree Name <RequiredAsterisk /></InputLabel>
                        <TextField
                            required
                            type='text'
                            name='degree_name'
                            variant='outlined'
                            placeholder='Enter Your Degree Name'
                            fullWidth
                            inputRef={degreeNameRef}
                            defaultValue={latestEduInfo.degree_name}
                            inputProps={{
                              style: {
                                padding: "10px 14px",
                              },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                    <Grid item lg={4} xs={12}>
                        <InputLabel sx={{ fontWeight: 'bold' }}>Grade / CGPA <RequiredAsterisk /></InputLabel>
                        <TextField
                            required
                            type='text'
                            name='degree_grade'
                            variant='outlined'
                            placeholder='Grade / CGPA'
                            fullWidth
                            inputRef={gradeRef}
                            defaultValue={latestEduInfo.degree_grade}
                            inputProps={{
                              style: {
                                padding: "10px 14px",
                              },
                            }}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <InputLabel sx={{ fontWeight: 'bold' }}>Start Date <RequiredAsterisk /></InputLabel>
                        <TextField
                            required
                            type='date'
                            name='degree_start'
                            variant='outlined'
                            fullWidth
                            inputRef={startDateRef}
                            defaultValue={latestEduInfo.degree_start}
                            onChange={dateHandleChange(setDegreeStart)}
                            inputProps={{
                              style: {
                                padding: "10px 14px",
                                color: isPlaceholderFormat(degreeStart) && (!latestEduInfo.degree_start || latestEduInfo.degree_start === '') ? '#949494' : 'black',
                              },
                              min: "1950-01-01",
                              max: getDobMaxYear(),
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} xs={12}>
                        <InputLabel sx={{ fontWeight: 'bold' }}>End Date <RequiredAsterisk /></InputLabel>
                        <TextField
                            required
                            type='date'
                            name='degree_end'
                            variant='outlined'
                            fullWidth
                            inputRef={endDateRef}
                            defaultValue={latestEduInfo.degree_end}
                            onChange={dateHandleChange(setDegreeEnd)}
                            inputProps={{
                              style: {
                                padding: "10px 14px",
                                color: isPlaceholderFormat(degreeEnd) && (!latestEduInfo.degree_end || latestEduInfo.degree_end === '') ? '#949494' : 'black',
                              },
                              min: "1950-01-01",
                              max: getDobMaxYear(),
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} mt={1}>
                    <Grid item lg={12} xs={12}>
                        <InputLabel sx={{ fontWeight: 'bold' }}>Description</InputLabel>
                        <TextField
                            id="exampleFormControlTextarea1"
                            placeholder="Write Desctiption Here"
                            type='text'
                            multiline
                            rows={3}
                            variant="outlined"
                            fullWidth
                            inputRef={descriptionRef}
                            defaultValue={latestEduInfo.degree_detail}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", pt: 4 }}>
                    <Button
                    variant='outlined'
                    // disabled
                    sx={{ mr: 1, minWidth: {sm: "102px"} }}
                    onClick={handleBack}
                    >
                    Back
                    </Button>
                    <Box sx={{ display: "flex" }}>
                      <LoadingButton
                        sx={{ ml: {xs: 1, md: 2, minWidth: {sm: "102px"}} }}
                        variant="contained"
                        loading={loading}
                        type="submit"
                      >
                        Continue
                      </LoadingButton>
                    </Box>
              </Box>
            </form>
            }
        </>
    )
}
