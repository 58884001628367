import { Alert, Button, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import RequiredAsterisk from "../../../components/CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk";

export default function JobPostCustomQuestions({
    customQuestionArray,
    setCustomQuestionArray,
    errors
}) {


    const [tempQuestion, setTempQuestion] = useState('')

    const suggestedQuestions = [
        'How soon are you available to start?',
        'Do you have IELTS score?'
    ]

    const [suggestedQuestionAdded, setSuggestedQuestionAdded] = useState([]);

    const compareSuggestedQuestions = () => {
        let newState = suggestedQuestionAdded;
        suggestedQuestions.forEach((sQItem, sQIndex) => {
            let q = customQuestionArray.find((item) => {
                return item.question === sQItem
            })

            if (q === undefined) {
                newState[sQIndex] = false;

            } else {
                newState[sQIndex] = true;
            }
        })

        setSuggestedQuestionAdded(newState);
    }

    const addCustomQuestion = () => {
        setCustomQuestionArray([...customQuestionArray, { question: tempQuestion, noOfOptions: 0, options: [] }])
        setTempQuestion('')
    }

    const addSuggestCustomQuestion = (question, index) => {
        setCustomQuestionArray([...customQuestionArray, { question: question, noOfOptions: 0, options: [] }])
        setSuggestedQuestionAdded({ ...suggestedQuestionAdded, [index]: true })
    }

    const updateCustomQuArray = (index, e) => {
        const list = [...customQuestionArray]
        list[index]['question'] = e.target.value
        setCustomQuestionArray(list)
    }

    const updateCustomQuestionsOptionsCount = (index, e) => {
        const list = [...customQuestionArray]
        list[index]['noOfOptions'] = e.target.value
        setCustomQuestionArray(list)
    }

    const createFilterOptions = (index) => {
        const list = [...customQuestionArray]
        let optionsArray = [];
        for(let i=0 ; i<list[index]['noOfOptions']; i++)
        {
            optionsArray[i]= ""
        }
        list[index]['options'] = optionsArray;
        setCustomQuestionArray(list);
    }

    const updateCustomQuestionsOptions = (index,optionIndex, e) => {
        const list = [...customQuestionArray]
        list[index]['options'][optionIndex] = e.target.value
        setCustomQuestionArray(list)
    }

    const deleteCustomQuestion = (index) => {
        const newList = customQuestionArray.filter((_, i) => i != index)
        console.log(newList);
        setCustomQuestionArray([...newList])
    }


    useEffect(() => {
        compareSuggestedQuestions();
        console.log(customQuestionArray);
    }, [customQuestionArray])

    return (
        <div className='row m-b20'>
            <div className='col-12 m-b20'>
                {
                    suggestedQuestions.map((item, index) => {
                        return (
                            <Chip label={item} sx={{ marginRight: '10px' }}
                                onClick={() => addSuggestCustomQuestion(item, index)}
                                disabled={suggestedQuestionAdded[index]}
                                variant="outlined" />
                        )
                    })
                }
            </div>
            {
                customQuestionArray?.map((item, index) => {
                    return (
                        <div className="shadow-sm p-3 mb-4 col-12" key={index} style={{ border: '1px solid #c7c7c775', borderRadius: '5px' }}>
                            <div className="row">
                                <div key={index} className='col-10 m-b10'>
                                    <label>Question {index + 1}</label>
                                    <input type='text' className='form-control' onChange={(e) => updateCustomQuArray(index, e)} value={item.question} />
                                </div>
                                <div className='col-2'>
                                    <label style={{ visibility: 'hidden' }}>Button</label> <br />
                                    <Button onClick={() => deleteCustomQuestion(index)}>
                                        <i className="fa fa-trash-o fa-2x" aria-hidden="true" ></i>
                                    </Button>
                                </div>
                                <div className="col-12 mb-3">
                                    <label>No of Options: <RequiredAsterisk /> </label>
                                    <div className="row" style={{ margin: 'unset' }}>
                                        <input type='number' max={20} className='form-control col-10' value={item?.noOfOptions ? item?.noOfOptions : ""} placeholder="Type options number" onChange={(e) => updateCustomQuestionsOptionsCount(index, e)}/>
                                        <Button variant="outlined" className="col-2" onClick={()=>createFilterOptions(index)}>Add</Button>
                                        <span className="mt-2">
                                            {errors.customQuestion && <Alert severity="error">{errors.customQuestion}</Alert>}
                                        </span>    
                                    </div>
                                </div>
                            </div>
                            {
                                item.options.map((optionItem,optionIndex) => {
                                    return (
                                        <div className="mb-2">
                                            <label>Option {optionIndex + 1} <RequiredAsterisk /></label>
                                            <input className="form-control" type="text" placeholder="Type options number" value={optionItem ? optionItem : ""} onChange={(e) => updateCustomQuestionsOptions(index,optionIndex, e)}/>
                                            <div className="mt-2">
                                                {errors.customQuestion && <Alert severity="error">{errors.customQuestion}</Alert>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
            <div className='col-10 m-t20'>
                {/* <hr /> */}
                <form id='custom-question-add'>
                    <label>Add Question</label>
                    <input type='text' className='form-control' value={tempQuestion} placeholder='Enter your question' onChange={(e) => setTempQuestion(e.target.value)} />
                </form>
            </div>
            <div className='col-2 m-t20'>
                <label style={{ visibility: 'hidden' }}>Button</label> <br />
                <Button onClick={() => addCustomQuestion()} disabled={tempQuestion === "" ? true : false} ><i className="fa fa-plus-square-o fa-2x" aria-hidden="true"></i></Button>
            </div>
        </div>
    )
}