import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Box, Dialog, Slide, DialogTitle, DialogContent, Stack, IconButton, Button, DialogActions } from '@mui/material';
import { Typography } from "@mui/material";
import EmployerBenefits from "./EmployerBenefitsSearch";
import { JobSearchContext } from "../../context/JobSearchContext";
import JobBenefits from "./JobBenefitSearch";
import { useLocation } from "react-router-dom";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CrossIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#FFDCDC" />
            <path d="M5.93246 9.69582C5.52683 10.1014 5.1223 10.506 4.71668 10.9116C4.46782 11.1605 4.46563 11.4784 4.69914 11.6987C4.78058 11.7769 4.88599 11.8254 4.99837 11.8364C5.11075 11.8473 5.22354 11.8201 5.31854 11.759C5.38703 11.7118 5.4509 11.6582 5.50929 11.599L7.85097 9.25731C8.02309 9.08519 7.97704 9.08519 8.15574 9.26169C8.94726 10.0532 9.73878 10.8447 10.5303 11.6363C10.789 11.895 11.1058 11.9048 11.3295 11.6702C11.4083 11.5895 11.4576 11.4846 11.4695 11.3725C11.4815 11.2603 11.4553 11.1474 11.3953 11.0519C11.349 10.9827 11.2958 10.9184 11.2363 10.8601C10.4448 10.0686 9.65217 9.27594 8.86065 8.48442C8.76308 8.38685 8.76198 8.33752 8.86065 8.24105C9.65217 7.44953 10.4437 6.658 11.2363 5.86539C11.2925 5.81139 11.3432 5.75189 11.3876 5.68779C11.4515 5.58862 11.4796 5.47054 11.4671 5.3532C11.4546 5.23587 11.4022 5.12636 11.3188 5.04292C11.2353 4.95948 11.1258 4.90715 11.0085 4.89463C10.8912 4.8821 10.7731 4.91015 10.6739 4.9741C10.6053 5.0211 10.5417 5.07511 10.4843 5.13526L8.15354 7.46597C7.97375 7.64576 8.02418 7.64576 7.83891 7.46049C7.06273 6.68432 6.28583 5.90741 5.50819 5.12978C5.45419 5.07354 5.3947 5.02286 5.3306 4.97849C5.23129 4.91236 5.11215 4.88267 4.99343 4.89445C4.87471 4.90624 4.76373 4.95878 4.67937 5.04314C4.59501 5.1275 4.54247 5.23848 4.53068 5.3572C4.5189 5.47592 4.54859 5.59506 4.61472 5.69436C4.6601 5.75766 4.7111 5.81673 4.7671 5.87087C5.55863 6.66239 6.35124 7.45501 7.14277 8.24653C7.23485 8.33861 7.23595 8.38795 7.14277 8.47894C6.74262 8.88566 6.33699 9.29129 5.93246 9.69582Z" fill="#DB1616" />
        </svg>
    );
}


export default function BenefitSearchComponent(props) {

    const [tabButtonActive, setTabButtonActive] = useState(false);

    const { checkedJobBenefitList, checkedEmpBenefitList, jobSearchData, setJobSearchData } = useContext(JobSearchContext)

    const handleClick = (isActive) => {
        setTabButtonActive(isActive);
    };

    const triggerSearch = () => {
        setJobSearchData({ ...jobSearchData, employer_benefit: checkedEmpBenefitList.length > 0 ? checkedEmpBenefitList.toString() : "", job_benefit: checkedJobBenefitList.length > 0 ? checkedJobBenefitList.toString() : "" })
    }

    const handleSubmit = () => {
        triggerSearch();
        props.handleClose();
    }

    let locationData = useLocation();

    useEffect(() => {
        if (locationData?.state?.fromHome) {
            setJobSearchData({ ...jobSearchData, job_benefit: checkedJobBenefitList.toString() })
        } else return
    }, [locationData]);


    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            onClose={props.handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: {
                    width: '1150px',
                    maxWidth: '1200px',
                },
            }}
        >
            <DialogTitle
                sx={{
                    borderBottom: '1px solid #D9D9D9',
                    "@media (max-width: 600px)": {
                        padding: 0,
                        paddingBottom: 2
                    },
                }}
            >
                <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                    <IconButton
                        onClick={props.handleClose}
                        sx={{
                            '&:focus': {
                                outline: 0,
                            },
                        }}
                    >
                        <CrossIcon />
                    </IconButton>
                </Box>
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={{ md: 'space-between', xs: 'center' }}
                    alignItems={'center'}
                    sx={{
                        "@media (max-width: 600px)": {
                            padding: 1,
                        },
                    }}
                >
                    <Box>
                        <Stack direction={{ md: 'row', xs: 'column' }} spacing={2} alignItems={'center'}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 600,
                                }}
                            >
                                Search Jobs By Benefits
                            </Typography>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={() => handleClick(false)}
                                    sx={{
                                        backgroundColor: !tabButtonActive ? '#E5F5FF' : '#f0f0f0',
                                        color: !tabButtonActive ? '#0079C1' : '#72737C',
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: tabButtonActive ? '#E5F5FF' : '#cfeafb',
                                            color: '#0079C1',
                                        },
                                        '&:focus': {
                                            outline: 0,
                                        },
                                    }}
                                >
                                    Job Specific
                                </Button>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={() => handleClick(true)}
                                    sx={{
                                        backgroundColor: tabButtonActive ? '#E5F5FF' : '#f0f0f0',
                                        color: tabButtonActive ? '#0079C1' : '#72737C',
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: !tabButtonActive ? '#E5F5FF' : '#cfeafb',
                                            color: '#0079C1',
                                        },
                                        '&:focus': {
                                            outline: 0,
                                        },
                                    }}
                                >
                                    Employer Specific
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
                        <IconButton
                            onClick={props.handleClose}
                            sx={{
                                '&:focus': {
                                    outline: 0,
                                },
                            }}
                        >
                            <CrossIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </DialogTitle>
            <DialogContent
                sx={{
                    "@media (max-width: 600px)": {
                        padding: 0,
                    },
                }}
            >
                <Box m={5}>
                    {
                        tabButtonActive ?
                            <EmployerBenefits />
                            :
                            <JobBenefits />
                    }
                </Box>
            </DialogContent>
            <DialogActions sx={{ borderTop: '1px solid #D9D9D9', padding: '20px', justifyContent: 'space-between' }}>
                <Stack direction={'row'} spacing={4}>
                    <IconButton
                        className="swiper-button-prev"
                        aria-label="previous"
                        sx={{
                            backgroundColor: '#E5F5FF',
                            padding: '20px',
                            '&:hover': {
                                backgroundColor: '#d1eaf9',
                            },
                            '&:focus': {
                                outline: 0,
                            },
                        }}
                    />
                    <IconButton
                        className="swiper-button-next"
                        aria-label="next"
                        sx={{
                            backgroundColor: '#E5F5FF',
                            padding: '20px',
                            '&:hover': {
                                backgroundColor: '#d1eaf9',
                            },
                            '&:focus': {
                                outline: 0,
                            },
                        }}
                    />
                </Stack>

                <Button
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                    sx={{
                        backgroundColor: '#0079C1',
                        '&:focus': {
                            outline: 0,
                        },
                    }}
                >
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    )
};
