import React, { useRef, useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Grid,
  InputLabel,
  MenuItem,
  Box,
  Divider,
  Button,
  Typography,
  Select,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  candidateRegisterFlow,
  getCurrencies,
  getSalaryTypes,
  getTypes,
  stepperStepInfo,
} from "../../../../fetchAPI";
import { LoadingButton } from "@mui/lab";
import { Skeleton } from "@mui/material";
import { fetchIpAddress, handleKeyDown, validatePhoneNumber } from "../../../helpers/helperFunctions";
import { useAlert } from "../../../context/AlertContext";
import RequiredAsterisk from "../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk";
import ClearIcon from '@mui/icons-material/Clear'; 

const getDobMaxYear = () => {
  let d = new Date();
  d.setFullYear(d.getFullYear());
  return d.toJSON().slice(0, 10);
};

const useStyles = makeStyles({
  input: {
    borderLeft: '1px solid #C4C4C4',
    // paddingLeft: "14px",
    marginLeft: "7px",
    padding: "10px 14px"
  },
});

export default function PersonalInformation({ handleNext, job_id, candidateData, candLoader, settingCandidateData }) {
  const [jobTypes, setJobTypes] = useState();
  const [currencies, setCurrencies] = useState();
  const [currencyType, setCurrencyType] = useState({});
  const [salaryTypes, setSalaryTypes] = useState();
  const [salaryType, setSalaryType] = useState("");
  const [salaryInfos, setSalaryInfos] = useState({})
  const [candidateTypeId, setcandidateTypeId] = useState("")
  const fullNameRef = useRef("");
  const emailRef = useRef("");
  const [gender, setGender] = useState("");
  const dobRef = useRef("");
  const phoneNumberRef = useRef("");
  const currentSalaryRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const alertContext = useAlert();
  const [saveButtonHoverEffect, setSaveButtonHoverEffect] = useState("outlined");

  const handleSubmit = () => {
    const phoneNumber = phoneNumberRef.current?.value
    if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
      alertContext.setOpenErrorAlert(true);
      alertContext.setResponseMessage("Please enter a valid phone number!");
      return;
    }

    const paramData = {
      first_name: fullNameRef.current?.value ? fullNameRef.current?.value : null,
      email : emailRef.current?.value ? emailRef.current?.value : null,
      gender: gender ? gender : null,
      dob: dobRef.current?.value,
      phone_no: phoneNumber,
      candidate_type_id: salaryInfos.candidate_type_id ? salaryInfos.candidate_type_id : candidateTypeId ? candidateTypeId : null,
      present_salary: currentSalaryRef.current?.value
        ? currentSalaryRef.current?.value
        : null,
      salary_type_id: salaryInfos?.salary_type_id ? salaryInfos.salary_type_id : salaryType ? salaryType : null,
      salary_currency: currencyType?.id ? currencyType?.id : currencyType ? currencyType : null,
    }

    setLoading(true);

    candidateRegisterFlow("personal_info", job_id, paramData)
    .then((response) => {
      if (response && response.success) {
        handleNext();
        setLoading(false);
      } else {
        alertContext.setOpenErrorAlert(true);
        alertContext.setResponseMessage(response.errors[0]?.message);
        setLoading(false);
      }
    })
    .finally(() => setLoading(false), alertContext.setResponseMessage(""));

    const ip = fetchIpAddress();
    const platform = "web";
    const stepNo = 1;

    stepperStepInfo(stepNo, ip, platform).then((response) => {
      if(response && response.success) {
        setLoading(false);
      }
    })
  };

  useEffect(() => {
    if(candidateData?.gender) {
      setGender(candidateData.gender)
    }
  }, [candidateData.gender])

  // get candidate job type
  useEffect(() => {
    getTypes().then((response) => {
      if (response) {
        setJobTypes(response.response.data);
      } else {
        console.log("error:", response);
      }
    });
  }, []);

  // get currency type
  useEffect(() => {
    getCurrencies().then((response) => {
      if (response) {
        setCurrencies(response.response.data);
      } else {
        console.log("error:", response);
      }
    });
  }, []);

  // get salary type
  useEffect(() => {
    getSalaryTypes().then((response) => {
      if (response) {
        setSalaryTypes(response.response.data);
      } else {
        console.log("error:", response);
      }
    });
  }, []);

  useEffect(() => {
    setSalaryInfos(candidateData?.candidate_salary_infos?.pop());
  },[candidateData.candidate_salary_infos]);

  useEffect(() => {
    setCurrencyType(candidateData?.salary_currency)
  }, [candidateData.salary_currency])
  
  // date field placeholder color change
  const [dob, setDob] = useState('');
  
  const dateHandleChange = (event) => {
    setDob(event.target.value);
  };

  const isPlaceholderFormat = (value) => {
    return value === '' || value === 'mm-dd-yyyy';
  };

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Typography align="left" variant="h6" sx={{ fontWeight: 600 }}>
          Personal Information
        </Typography>
      </Box>
      <Divider />
      {candLoader ? (
        <div className="mt-3 col-lg-12 col-md-12">
          <Skeleton variant="text" height={60} />
          <Skeleton variant="text" height={50} />
          <div className="text-left">
            <Skeleton variant="text" />
          </div>
          <div className="mt-3 text-left">
            <Skeleton variant="rounded" height={120} />
          </div>
          <div className="text-left">
            <Skeleton variant="text" width={80} height={50} />
          </div>
        </div>
      ) : (
        <form
          className="ga_stepper_candidate_register_personal_info_form" // do not remove ga_ class
          onSubmit={(e) => [e.preventDefault(), handleSubmit()]}
        >
          <Grid container spacing={2} mt={3}>
            <Grid item lg={6} xs={12}>
              <InputLabel sx={{ fontWeight: "bold" }}>
                Candidate Full Name <RequiredAsterisk />
              </InputLabel>
              <TextField
                required
                type="text"
                name="name"
                variant="outlined"
                placeholder="Enter Your Name"
                fullWidth
                inputRef={fullNameRef}
                defaultValue={candidateData.first_name}
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <InputLabel sx={{ fontWeight: "bold" }}>
                Email Address
              </InputLabel>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                placeholder="Enter Your Email Address"
                fullWidth
                inputRef={emailRef}
                defaultValue={candidateData.user?.email}
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            <Grid item lg={4} xs={12} sx={{position: "relative"}}>
            <InputLabel sx={{ fontWeight: "bold" }}>
                Gender <RequiredAsterisk />
              </InputLabel>
              <Select
                required
                displayEmpty
                name="gender"
                fullWidth
                value={gender}
                onChange={(e)=> setGender(e.target.value)}
                input={<OutlinedInput />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  '& .MuiSelect-select': {
                     padding: "10px 14px"
                  }
                }}
              >
                <MenuItem disabled value="">
                <span style={{color: '#949494'}}>Select Gender</span>
                </MenuItem>
                <MenuItem value={"male"} key={"male"}>
                  Male
                </MenuItem>
                <MenuItem value={"female"} key={"female"}>
                  Female
                </MenuItem>
                <MenuItem value={"others"} key={"others"}>
                  Others
                </MenuItem>                 
              </Select>
             {gender && 
                <IconButton size="small" onClick={() => setGender("")} sx={{position: "absolute", right: 25, bottom: 7}}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              }
            </Grid>

            <Grid item lg={4} xs={12}>
              <InputLabel sx={{ fontWeight: "bold" }}>
                Date of Birth <RequiredAsterisk />
              </InputLabel>
              <TextField
                required
                type="date"
                name="dob"
                variant="outlined"
                fullWidth
                inputRef={dobRef}
                defaultValue={candidateData?.dob}
                onChange={dateHandleChange}
                InputProps={{
                  inputProps: {
                    min: "1950-01-01",
                    max: getDobMaxYear(),
                    style: {
                      padding: "10px 14px",
                      color: isPlaceholderFormat(dob) && (!candidateData.dob || candidateData.dob === '') ? '#949494' : 'black',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <InputLabel sx={{ fontWeight: "bold" }}>
                Phone Number <RequiredAsterisk />
              </InputLabel>
              <TextField
              className="phone_input"
                required
                type="number"
                name="phone_no"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+880</InputAdornment>
                  ),
                  onKeyDown: (e) => handleKeyDown(e),
                  classes: {
                    input: classes.input 
                  }
                }}
                inputRef={phoneNumberRef}
                defaultValue={candidateData?.phone_no?.substring(4)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item lg={6} xs={12} sx={{position: "relative"}}>
              <InputLabel sx={{ fontWeight: "bold" }}>Job Type</InputLabel>
              <Select
                displayEmpty
                name="candidate_type_id"
                fullWidth
                value={salaryInfos?.candidate_type_id ? salaryInfos.candidate_type_id : candidateTypeId}
                onChange={(e) => {
                  setcandidateTypeId(e.target.value)
                }}
                input={<OutlinedInput />}
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  '& .MuiSelect-select': {
                     padding: "10px 14px"
                  }
                }}
              >
                <MenuItem disabled value="">
                  <span style={{color: '#949494'}}>Select Job Type</span>
                </MenuItem>
                {jobTypes?.length > 0
                  ? jobTypes?.map((type) => (
                      <MenuItem value={type.id} key={type.id}>
                        {type.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
              {(salaryInfos?.candidate_type_id || candidateTypeId) && 
                <IconButton size="small" onClick={() => {setcandidateTypeId(""); setSalaryInfos({...salaryInfos, candidate_type_id: null})}} sx={{position: "absolute", right: 25, bottom: 7}}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              }
            </Grid>

            <Grid item lg={6} xs={12} sx={{position: "relative"}}>
              <InputLabel sx={{ fontWeight: "bold" }}>Salary Type</InputLabel>
              <Select
                displayEmpty
                name="salary_type_id"
                fullWidth
                value={salaryInfos?.salary_type_id ? salaryInfos.salary_type_id : salaryType}
                onChange={(e) => {
                  setSalaryType(e.target.value)
                }}
                input={<OutlinedInput />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  '& .MuiSelect-select': {
                     padding: "10px 14px"
                  }
                }}
              >
                <MenuItem disabled value="">
                <span style={{color: '#949494'}}>Select Salary Type</span>
                </MenuItem>
                {salaryTypes?.length > 0
                  ? salaryTypes?.map((salary) => (
                      <MenuItem value={salary.id} key={salary.id}>
                        {salary.type}
                      </MenuItem>
                    ))
                  : null}
              </Select>
              {(salaryInfos?.salary_type_id || salaryType) && 
                <IconButton size="small" onClick={() => {setSalaryType(""); setSalaryInfos({...salaryInfos, salary_type_id: null})}} sx={{position: "absolute", right: 25, bottom: 7}}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              }
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item lg={6} xs={12} sx={{position: "relative"}}>
              <InputLabel sx={{ fontWeight: "bold" }}>
                Salary Currency
              </InputLabel>
              <Select
                displayEmpty
                name="salary_currency"
                fullWidth
                value={currencyType?.id ? currencyType?.id : currencyType ? currencyType : ""}
                onChange={(e) => {
                  setCurrencyType(e.target.value)
                }}
                input={<OutlinedInput />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  '& .MuiSelect-select': {
                     padding: "10px 14px"
                  }
                }}
              >
                <MenuItem disabled value="">
                  <span style={{color: '#949494'}}>Select Currency Type</span>
                </MenuItem>
                {currencies?.length > 0
                  ? currencies?.map((currency) => (
                      <MenuItem value={currency.id} key={currency.id}>
                        {currency.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
              {(currencyType || currencyType?.id) && 
                <IconButton size="small" onClick={() => setCurrencyType("")} sx={{position: "absolute", right: 25, bottom: 7}}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              }
            </Grid>
            <Grid item lg={6} xs={12}>
              <InputLabel sx={{ fontWeight: "bold" }}>
                Current Salary
              </InputLabel>
              <TextField
                type="number"
                name="present_salary"
                variant="outlined"
                placeholder="Enter Your Current Salary"
                fullWidth
                inputRef={currentSalaryRef}
                defaultValue={candidateData?.present_salary}
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                  onKeyDown: (e) => handleKeyDown(e),
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 4 }}>
            <Button variant="outlined" disabled sx={{ mr: 1, minWidth: {sm: "102px"} }}>
              Back
            </Button>
            <Box sx={{ display: "flex" }}>
              <LoadingButton
                sx={{ ml: {xs: 1, md: 2}, minWidth: {sm: "102px"} }}
                variant="contained"
                loading={loading}
                type="submit"
              >
                Continue
              </LoadingButton>
            </Box>
          </Box>  
        </form>
      )}
    </>
  );
}
