import React, { useContext, useEffect, useState } from 'react'
import { updateCandidateBasicInfo, updateCandidateSalaryInfo } from '../../../../../fetchAPI';
import { DropdownContext } from '../../../../context/DropdownContext';
import Select from 'react-select'
import customStyles from './CandidateUpdateProfile.module.css'
import { useAlert } from '../../../../context/AlertContext';

function UpdateSalary(props) {

    const { types, salary, currency } = useContext(DropdownContext)
    const [updateCandidateSalaryData, setUpdateCandidateSalaryData] = useState('');
    const [updateData, setUpdateData] = useState({})
    const [multiselectValue,setMultiselectValue] = useState([])

    const alertContext = useAlert();

    const handleChange = (name) => (event) => {
        setUpdateCandidateSalaryData({ [name]: event.target.value })
    }

    const handleChangeSelect = (name) => (event) => {
        
        setUpdateCandidateSalaryData({ ...updateCandidateSalary, [name]: event.value })
    }

    const handleChangeMultiSelect = (name) => (event) => {
        setMultiselectValue(event)
        setUpdateData({ [name]: event.map(val => val.value).toString() })
    }

    useEffect(()=>{
        setMultiselectValue(props.candidateTypes?.map((item) => {
            return (
                { label: item.name, value: item.id }
            )
        }))
    },[props.candidateTypes])

    const updateCandidate = (candId, updateParameters) => {
        updateCandidateBasicInfo(candId, updateParameters)
            .then(response => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                } else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                }
            })
    }

    const updateCandidateSalary = (id, typeID, salaryParamters) => {

        salaryParamters = { ...salaryParamters, candidate_type_id: typeID }


        updateCandidateSalaryInfo(id, salaryParamters)
            .then(response => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                } else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)

                }
            })
    }

    return (
        <div id='salary_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Salary Information</div>
                    </div>
                </div>
                <div className='card-body'>
                    <div>
                        <div className="form-group">
                            <label className='mb-2'>Type</label>
                            <Select
                                value={multiselectValue}
                                options={types?.map((item, index) => {
                                    return (
                                        { label: item.name, value: item.id }
                                    )
                                })}
                                className='atb-input-box-container'
                                classNamePrefix='atb-input-box'
                                onChange={handleChangeMultiSelect('candidate_type_id')}
                                placeholder='Add Type'
                                isMulti
                            />
                        </div>
                        <div className='text-c mb-2' >
                            <button onClick={() => updateCandidate(props.candidateID, updateData)} style={{padding:'8px 80px'}} className='btn btn-primary'>Save</button>
                        </div>
                    </div>
                    {
                        props.candidateTypes && props.candidateTypes.length !== 0 ? props.candidateTypes.map((type, index) => {
                            return (
                                <div key={index}>
                                    <div className='container'>
                                        <div className='row'>
                                            <h5 className='ml-3'>{type?.name}</h5>
                                        </div>

                                        <div className='row'>
                                            <div className="col-lg-6 col-xl-3 col-md-6">
                                                <div className="form-group">
                                                    <p className=''>Current Salary</p>
                                                    <input type="number" max="2000000" onChange={handleChange('present_salary')} onKeyDown={(e) => {if (e.target.value && parseInt(e.target.value + e.key) > 2000000) {e.preventDefault();}}} defaultValue={type?.pivot?.present_salary} className="form-control " style={{height: '55px',borderRadius: '5px',boxShadow: '0px 0px 4px #d5d5d5c7'}} onBlur={() => updateCandidateSalary(props?.candidateID, type?.id, updateCandidateSalaryData)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6">
                                                <div className="form-group">
                                                    <p className=''>Expected Salary</p>
                                                    <input type="number" max="2000000" onChange={handleChange('expected_salary')} onKeyDown={(e) => {if (e.target.value && parseInt(e.target.value + e.key) > 2000000) {e.preventDefault();}}} defaultValue={type?.pivot?.expected_salary} className="form-control" style={{height: '55px',borderRadius: '5px',boxShadow: '0px 0px 4px #d5d5d5c7'}} onBlur={() => updateCandidateSalary(props?.candidateID, type?.id, updateCandidateSalaryData)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6">
                                                <div className="form-group">
                                                    <p>Currency</p>
                                                    <Select
                                                        defaultValue={{ label: type?.pivot.salary_currency_name, value: type?.pivot.salary_currency }}
                                                        options={currency?.map((item, index) => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        })}
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('salary_currency')}
                                                        placeholder=''
                                                        onBlur={() => updateCandidateSalary(props?.candidateID, type?.id, updateCandidateSalaryData)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6">
                                                <div className="form-group">
                                                    <p>Time Period</p>
                                                    <Select
                                                        defaultValue={{ label: type?.pivot.salary_type_name, value: type?.pivot.salary_type_id }}
                                                        options={salary?.map((item, index) => {

                                                            return (

                                                                { label: item.type, value: item.id }

                                                            )
                                                        })}
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('salary_type_id')}
                                                        placeholder=''
                                                        onBlur={() => updateCandidateSalary(props?.candidateID, type?.id, updateCandidateSalaryData)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        })
                            :
                            <small>Please select your <b>Type</b> to provide salary informations.</small>
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdateSalary;
