import React, { useState, useContext } from 'react'
import { postCandidateExperienceByID, updateCandidateCareerInfo } from '../../../../../fetchAPI';
import Select from 'react-select'
import customStyles from './CandidateUpdateProfile.module.css'
import CandidateUpdateModal from './component/CandidateUpdateModal';
import EditSVG from './component/EditSVG';
import { useAlert } from '../../../../context/AlertContext';
import { DropdownContext } from '../../../../context/DropdownContext';
import SingleCareerInfoBlock from './component/SingleCareerInfoBlock';
import { Box, CircularProgress } from '@mui/material';

function UpdateCareerInfo(props) {

    const { district, departments, industries, levels, qualifications } = useContext(DropdownContext);

    const [careerData, setCareerData] = useState({})
    const [loader, setLoader] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();

    const handleChangeSelectMultiValue = (name) => (event) => {
        setCareerData({ ...careerData, [name]: event })
    }

    const handleChangeSelect = (name) => (event) => {
        setCareerData({ ...careerData, [name]: event.value, [name + 'Name']: event.label })
    }

    const updateInfo = (body) => {

        // console.log('body', JSON.stringify(body));
        // return;

        let requestBody = {
            qualification: body.qualification,
            level: body.level,
            districtIds: body.locations.map((item) => item.value).join(),
            departmentIds: body.departments.map((item) => item.value).join(),
            industryIds: body.industries.map((item) => item.value).join()
        }

        // console.log(requestBody);
        // return;

        setAddModalOpen(false);
        setCareerData({});
        updateCandidateCareerInfo(requestBody).then((response) => {
            if (response && response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.renderState()
                setLoader(false);
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false);
            }
        })
    }

    return (
        <div id='update_candidate_career_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Career & Application Info</div>
                        <button className={`btn ${customStyles.editButton}`} onClick={() => {
                            setCareerData({
                                qualification: props.data.qualification?.id,
                                level: props.data.level?.id,
                                qualificationName: props.data.qualification?.name,
                                levelName: props.data.level?.candidate_level_name,
                                locations: props.data.preferred_districts.map((item) => { return { label: item.name, value: item.id } }),
                                departments: props.data.preferred_jobcategories.map((item) => { return { label: item.name, value: item.id } }),
                                industries: props.data.preferred_organization_types.map((item) => { return { label: item.name, value: item.id } }),
                            });
                            setAddModalOpen(true)
                        }}>
                            <div style={{ marginRight: '10px' }}>Edit</div>
                            <EditSVG />
                        </button>
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            <>
                                <SingleCareerInfoBlock title="Looking For" value={`${props.data.level?.candidate_level_name || ''} Level Job`} />
                                <SingleCareerInfoBlock title="Qualification" value={props.data.qualification?.name} />
                                <SingleCareerInfoBlock title="Preferred Job Category" value={props.data.preferred_jobcategories?.map((item) => item.name).join(' , ')} />
                                <SingleCareerInfoBlock title="Preferred District" value={props.data.preferred_districts?.map((item) => item.name).join(' , ')} />
                                <SingleCareerInfoBlock title="Preferred Organization Types" value={props.data.preferred_organization_types?.map((item) => item.name).join(' , ')} />
                            </>
                    }
                </div>
            </div>
            <CandidateUpdateModal form="candidateCareerInfoUpdateForm" open={addModalOpen} onClose={() => [setAddModalOpen(false), setCareerData({})]} title='Career & Application Info'>
                <div style={{overflowY: "scroll"}}>
                    <div className='card-body' >
                        <form id='candidateCareerInfoUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateInfo(careerData); }}>
                            <div className={`row text-left`}>


                                <div className="col-lg-6 col-md-6" style={{ zIndex: '8' }}>
                                    <div className="form-group">
                                        <label>Qualification</label>
                                        <Select
                                            // defaultValue={{ label: props.basicData.qualification?.name, value: props.basicData.qualification?.id }}
                                            value={{ value: careerData.qualification, label: careerData.qualificationName }}
                                            options={qualifications?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('qualification')}
                                            placeholder='Add Qualification'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6" style={{ zIndex: '7' }}>
                                    <div className="form-group">
                                        <label>Level</label>
                                        <Select
                                            // defaultValue={{ label: props.basicData.level?.candidate_level_name, value: props.basicData.level?.id }}
                                            value={{ value: careerData.level, label: careerData.levelName }}
                                            options={levels?.map((item) => {
                                                return (
                                                    { label: item.candidate_level_name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('level')}
                                            placeholder='Add Level'

                                        />
                                    </div>
                                </div>



                                <div className='col-md-6 col-lg-6' style={{ zIndex: '6' }}>
                                    <div className="form-group">
                                        <label>Preferred Organization Type</label>
                                        <Select
                                            placeholder='Select Preferred Organization Type'
                                            options={industries?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            value={careerData.industries}
                                            isMulti
                                            onChange={handleChangeSelectMultiValue('industries')}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'

                                        />
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6' style={{ zIndex: '5' }}>
                                    <div className="form-group">
                                        <label>Preferred Job Category</label>
                                        <Select
                                            placeholder='Select Preferred Category'
                                            options={departments?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            value={careerData.departments}
                                            isMulti
                                            onChange={handleChangeSelectMultiValue('departments')}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6' style={{ zIndex: '4' }}>
                                    <div className="form-group">
                                        <label>Preferred Locations</label>
                                        <Select
                                            placeholder='Select Preferred Locations'
                                            options={district.map(item => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })
                                            }
                                            value={careerData.locations}
                                            isMulti
                                            onChange={handleChangeSelectMultiValue('locations')}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                        />
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
        </div>
    )

}

export default UpdateCareerInfo